import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import Layout from '../../components/Layout'
import { useDispatch, useSelector } from 'react-redux'

import _ from 'lodash'
import { useUrlSearchParams } from '../../hooks/use-url-search-params'
import { getDetailedPaginationProperties } from '../../utils'
import { ACTION_COL_OPTIONS, FILTER_CONSTANTS, API_ENDPOINTS } from '../../constants'

// import Axios from 'axios';
import { showLoader, hideLoader, showErrorMessage, showConfirmMessage } from '../../services/loader/actions'
import { showSnackbarWithTimeout } from '../../services/snackbar/actions'
import {
	Icon,
	Typography,
	Switch,
	FormControlLabel,
	makeStyles,
	withWidth,
	Slide,
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
	LinearProgress,
	Divider,
	useMediaQuery,
	useTheme,
	Grow,
	Fab,
	Grid,
	IconButton,
	TextField,
	Select,
	MenuItem,
	Badge,
} from '@material-ui/core'
// import { clearAllFilters } from '../../services/filters/actions';
import ImagePopUp from '../../components/ImagePopUp'
// import { startOfDay, endOfDay } from 'date-fns';
import FlagComponent from '../../components/Flags'
import { getModelxTable } from '../../services/modelx/actions'
// import {getClientId} from '../../services/clientId/actions'
// import {getBpId} from '../../services/bpId/actions';
import { format } from 'date-fns'
import Axios from 'axios'
import { usePrevious } from 'hooks'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { usePermission } from 'hooks/usePermission'
import { setThisFilter, setFilter } from 'services/modelxFiltersNew/actions'
import { TASK_BUTTON_TXT } from '../../constants'
import withAddTaskDialog from 'hoc/withAddStickyDialog'
import withDashboardModal from 'hoc/withDashboardModal'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import classnames from 'classnames'
import { compose } from 'redux'
import { useHistory } from 'react-router-dom'
import ModelxApi from '../../services/modelx/api'
import { getErrMsg, getSuccessMsg } from 'utils'
import PrimaryButton from '../../components/PrimaryButton'
import PageHeader from '../../components/PageHeader'
import TableDeskBody from '../../components/ProductTable/TableDeskBody'
import LoadingIndicator from '../../components/LoadingIndicator'
import { DashboardImage } from 'illustrators'
import ModelxNewFilters from '../../components/ModelxFiltersNew'
import FullScreenDialog from 'components/FullScreenDialog'
import Slider from './Slider'
import ExportDialog from '../../components/ExportDialog'
import moment from 'moment'
import PageHeaderSort from 'components/PageHeaderSort'
import FilterApi from '../../services/modelxFiltersNew/api'
import withShareDialog from 'hoc/withShareDialog'

const MENU_WIDTH = 320

const useStyles = makeStyles((theme) => ({
	root: {
		// width: 42,
		// height: 26,
		// padding: 0,
		// margin: theme.spacing(1),

		width: '100%',
		height: 'calc(100vh - 0px)',
		position: 'relative',
		boxSizing: 'border-box',
		'& textarea': {
			font: 'inherit',
		},
		'& a.sc-iemWCZ.ivkEkN': {
			textAlign: 'center',
			textDecoration: 'none',
		},
	},
	containerRoot: {
		overflow: 'hidden',
	},
	rootContainer: {
		// height: '100%',
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.almostBlack[0],
			'& + $track': {
				backgroundColor: theme.palette.error.main,
				opacity: 1,
				height: 24,
				border: `1px solid transparent`,
			},
		},
		'&$focusVisible $thumb': {
			color: theme.palette.error.main,
			border: '4px solid #fff',
		},
	},
	thumb: {
		width: 24,
		height: 24,
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.almostBlack[500]}`,
		backgroundColor: theme.palette.almostBlack[300],
		opacity: 1,
		height: 24,
		transition: theme.transitions.create(['background-color', 'border']),
	},
	checked: {},
	focusVisible: {},
	inputLabel: {
		marginRight: 0,
		'& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
			// fontFamily: ['"Poppins"'].join(','),
			fontSize: theme.typography.pxToRem(14),
		},
	},
	sellingPriceText: {
		fontSize: theme.typography.pxToRem(14),
		fontWeight: 500,
		color: theme.palette.info.main,
		paddingRight: theme.spacing(0.5),
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	marketPriceText: {
		fontSize: theme.typography.pxToRem(14),
		// color: theme.palette.almostBlack[500],
		// textDecoration: 'line-through',
		fontWeight: 500,
		paddingRight: theme.spacing(0.5),
		color: theme.palette.success.main,
	},
	discountText: {
		fontSize: theme.typography.pxToRem(13),
		color: theme.palette.secondary.dark,
		paddingRight: theme.spacing(0.5),
	},
	dialog: {
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
		},
		maxWidth: '350px',
		maxHeight: 'calc(100% - 36px)',
	},
	disableOnUpdate: {
		opacity: (props) => (props.isLoading ? 0.8 : 1),
		pointerEvents: (props) => (props.isLoading ? 'none' : 'unset'),
	},
	dialogContent: {
		'& .MuiFormControl-marginDense': {
			marginTop: '4px',
		},
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: `${theme.palette.primary.main}`,
		},
	},
	dialogFooter: {
		padding: '12px 8px',
		borderTop: `1px solid ${theme.palette.grey[300]}`,
		'& .MuiButton-label': {
			textTransform: 'capitalize',
		},
	},
	muiIconButtonRoot: {
		// border: `1px solid ${theme.palette.almostBlack[300]}`,
		// borderRadius: `8px`,
		padding: `1px`,
		margin: '0px 8px',
		'& .MuiIcon-root': {
			color: theme.palette.almostBlack[600],
			fontSize: `1.75rem`,
		},
	},
	muiIconButtonDisabled: {
		// border: `1px solid ${theme.palette.almostBlack[200]}`,
		'& .MuiIcon-root': {
			color: theme.palette.almostBlack[400],
			fontSize: `1.75rem`,
		},
	},
	muiButtonRoot: {
		fontSize: theme.typography.pxToRem(14),
		lineHeight: theme.typography.pxToRem(14),
		borderRadius: '50%',
		color: theme.palette.almostBlack[600],
		fontFamily: ['"Roboto"', '"sans-serif"'].join(','),
		letterSpacing: theme.typography.pxToRem(0.1),
		fontWeight: '500',
		margin: '0px 8px',
		minWidth: 32,
		width: 32,
		height: 32,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'&.active': {
			color: theme.palette.almostBlack[0],
			background: theme.palette.primary.main,
		},
	},
	threeDotsDiv: {
		fontSize: theme.typography.pxToRem(14),
		color: theme.palette.almostBlack[600],
		fontFamily: ['"Roboto"', '"sans-serif"'].join(','),
		letterSpacing: theme.typography.pxToRem(0.1),
		minWidth: 32,
		width: 32,
		height: 32,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	muiSelectOutlined: {
		fontSize: theme.typography.pxToRem(14),
		color: theme.palette.almostBlack[600],
		fontFamily: ['"Roboto"', '"sans-serif"'].join(','),
		letterSpacing: theme.typography.pxToRem(0.1),
		padding: `7px`,
		paddingRight: `${theme.spacing(4.5)}px !important`,
		// border: `1px solid ${theme.palette.almostBlack[300]}`,
		'&.MuiSelect-select:focus': {
			borderRadius: '4px',
		},
		borderRadius: `4px`,
		'&::after': {
			content: '""',
			position: 'absolute',
			height: '100%',
			width: 1,
			background: theme.palette.almostBlack[300],
			top: '0px',
			right: '26px',
		},
	},
	muiSelectIconOutlined: {
		right: `3px`,
	},
	pageLinksContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		paddingRight: theme.spacing(2),
		[theme.breakpoints.down(600)]: {
			paddingRight: '0px',
			maxWidth: '100%',
		},
		[theme.breakpoints.down(470)]: {
			display: 'flex',
			flexDirection: 'column',
		},
	},
	viewSummaryContainer: {
		display: 'flex',
		// justifyContent: 'flex-start',
		alignItems: 'center',
		paddingLeft: theme.spacing(2),
		letterSpacing: theme.typography.pxToRem(0.1),
		lineHeight: theme.typography.pxToRem(14),
		color: theme.palette.almostBlack[800],
		[theme.breakpoints.down(600)]: {
			justifyContent: 'center',
			padding: '4px 0px 16px 0px',
			maxWidth: '100%',
		},
	},
	paginationContainer: {
		borderTop: `1px solid ${theme.palette.almostBlack[200]}`,
		padding: `${theme.spacing(4)}px 8px`,
		[theme.breakpoints.down(600)]: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	pagination: {
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down(470)]: {
			paddingBottom: '16px',
		},
		'& .MuiFormControl-root': {
			width: '40px',
			alignSelf: 'center',
			'& .MuiInputBase-inputMarginDense': {
				fontSize: '0.875rem',
				fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
				fonWeight: '400',
				lineHeight: '1.43',
				letterSpacing: '0.01071em',
			},
		},
		'& .MuiTypography-body1': {
			fontSize: '0.875rem',
			fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
			fonWeight: '400',
			lineHeight: '1.43',
			letterSpacing: '0.01071em',
			alignSelf: 'center',
		},
	},
	fab: {
		position: 'fixed',
		zIndex: 100,
		bottom: '54px',
		right: '24px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	menuContainer: {
		width: 0,
		opacity: 0,
		height: '100%',
		boxSizing: 'border-box',
		transition: 'width 200ms',
		[theme.breakpoints.down('xs')]: {
			width: `0.00001vw`,
		},
	},
	closeMenu: {
		display: 'none',
	},
	openMenu: {
		width: MENU_WIDTH,
		opacity: 1,
		zIndex: 1,
		height: '100%',
		boxShadow: `0px 0px 4px 0px ${theme.palette.almostBlack[400]}`,
		background: 'white',
		position: 'fixed',
		right: '0',
		top: '64px',
		[theme.breakpoints.down(600)]: {
			top: '57px',
		},
		[theme.breakpoints.down(330)]: {
			width: 'auto',
		},
	},
	pickerPageContent: {
		paddingTop: '2px',
		position: 'relative',
	},
}))

const FormSchema = Yup.object().shape({
	enable_dashboard: Yup.bool(),
	dashboard_id: Yup.object()
		.nullable()
		.when('enable_dashboard', { is: (type) => type === true, then: Yup.object().nullable().required('Please Select Dashboard.') }),
	ds_id: Yup.object()
		.nullable()
		.when('enable_dashboard', { is: (type) => type === true, then: Yup.object().nullable().required('Please Select Data Source.') }),
	field_name: Yup.string().when('enable_dashboard', {
		is: (type) => type === true,
		then: Yup.string().required('Please Select Dashboard Filter Column.'),
	}),
})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const Modelx = ({
	width,
	openStickyDialog,
	openDashboard,
	closeDashboard,
	isDashDialogOpened,
	match,
	isPickerMode = false,
	setOpenModel,
	handleCancel,
	cancelButtonLabel,
	redirectData = null,
	isPickFrom = null,
}) => {
	const dispatch = useDispatch()
	const [sortSettings, setSortSettings] = useState({
		keyToSort: '',
		sortDirection: true,
	})

	const history = useHistory()

	const theme = useTheme()
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('550'))

	const onBackButtonEvent = (e) => {
		e.preventDefault()
		// var currentLocation = window.location.pathname;
		history.goBack()
	}

	useEffect(() => {
		window.addEventListener('popstate', onBackButtonEvent)
		return () => {
			window.removeEventListener('popstate', onBackButtonEvent)
		}
	}, [])

	const [searchQuery, setSearchQuery] = useState('')
	const [filterMenuProps, setMenuProps] = useState({ isOpen: false, page: 'menulist', isButtonVisible: true })
	const appliedFilters = useSelector((state) => state.modelxFiltersNew)
	const sticky = useSelector((state) => state.snackbar)
	const [prodIDListForFilter, setProdIDListForFilter] = useState([])
	const [tableData, setTableData] = useState([])
	// const { date_range } = useSelector((state) => _.get(state, 'modelxFiltersNew', {}))
	const smallScreen = useMediaQuery(theme.breakpoints.down('1098'))

	const glassPerms = usePermission(ROLE_MODULES.GLASS)
	const isTaskAddAllowed = useMemo(() => _.get(glassPerms, PERMISSION_TYPE.CREATE, false), [glassPerms])

	const modelPerms = usePermission(ROLE_MODULES.MODELX)
	const isDeleteAllowed = useMemo(() => _.get(modelPerms, PERMISSION_TYPE.DELETE, false), [modelPerms])
	const isExportAllowed = useMemo(() => _.get(modelPerms, PERMISSION_TYPE.EXPORT, false), [modelPerms])
	const isEditAllowed = useMemo(() => _.get(modelPerms, PERMISSION_TYPE.EDIT, false), [modelPerms])

	const peoplexPerms = usePermission(ROLE_MODULES.PEOPLEX)
	const isViewAllowed = useMemo(() => _.get(peoplexPerms, PERMISSION_TYPE.VIEW, false), [peoplexPerms])
	// const isCreateAllowed = useMemo(() => _.get(peoplexPerms, PERMISSION_TYPE.CREATE, false), [peoplexPerms])
	// const isEditAllowed = useMemo(() => _.get(peoplexPerms, PERMISSION_TYPE.EDIT, false), [peoplexPerms])
	// const isDeleteAllowed = useMemo(() => _.get(peoplexPerms, PERMISSION_TYPE.DELETE, false), [peoplexPerms])

	const filtersState = useSelector((state) => state.modelxFiltersNew)
	const labelAdjective = useSelector((state) => _.get(state, 'appSettings.data.company_adjective', 'Counterfeit'))
	const dataSourceId = useSelector((state) => _.get(state, 'appSettings.data.ds_id'))

	const appSettingsData = useSelector((state) => _.get(state, 'appSettings.data'))
	const appSettingsLoading = useSelector((state) => _.get(state, 'appSettings.isLoading'))

	const [selectionMode, setSelectionMode] = useState(false)

	const classes = useStyles()
	const handleSearchInputChange = (e) => {
		setSearchQuery(e.target.value)
		setProdIDListForFilter([])
		setIsFilterChanged(true)
	}

	const [sortingDetails, setSortingDetails] = useState({
		sort_by: !_.isEmpty(filtersState?.sort_by) ? filtersState?.sort_by : null,
		sort_by_order: filtersState?.sort_by_order,
	})
	const sortableFields = [
		{ id: 'model_name', name: 'Model Name' },
		{ id: 'sorting_sticky_code', name: 'Model ID' },
		{ id: 'created_date', name: 'Created Date' },
		{ id: 'modified_date', name: 'Modified Date' },
		{ id: 'status', name: 'Status' },
		{ id: 'bot_version', name: 'Version' },
		{ id: 'revisions', name: 'Revisions' },
	]

	useEffect(() => {
		setSortingDetails({ sort_by: !_.isEmpty(filtersState?.sort_by) ? filtersState?.sort_by : null, sort_by_order: filtersState?.sort_by_order })
	}, [filtersState])

	// const sortFilter = (sortSettingObj) => {
	// 	return sortSettingObj && sortSettingObj.keyToSort === '' ? {} : { [sortSettingObj.keyToSort]: sortSettingObj.sortDirection ? 1 : -1 }
	// }

	const sortFilter = useCallback(
		(field, type) => {
			let filters = { ...filtersState, sort_by: field, sort_by_order: type }
			// console.log('sort filter', filters, field, type)
			FilterApi.updateFilter(filters, isPickFrom)
				.then((res) => {
					dispatch(setFilter(res.data.data.filter))
				})
				.catch((err) => {
					// console.log(err, 'err')
				})
		},
		[filtersState]
	)

	const onCheckBoxClick = (e, record) => {
		e.stopPropagation()
		// console.log("check box clixked", record)
	}

	// const setTimeParams = (dateObj) => {
	// 	// const startDateTime = startOfDay(dateObj.startDate);
	// 	const startDateTime = dateObj.startDate //format(dateObj.startDate, 'yyyy-MM-dd');
	// 	const endDateTime = dateObj.endDate //format(dateObj.endDate, 'yyyy-MM-dd');
	// 	return { startDate: startDateTime, endDate: endDateTime, key: 'dates' }
	// }

	const mobileHeaderKeys = {
		id_2: 'model_id',
		rating: 'rating',
		title: 'model_name',
		subtitle1: 'created_by',
		subtitle2: 'status',
	}

	const mobileContent = [
		{
			name: 'Version',
			dataKey: 'bot_version',
			size: 6,
		},
		{
			name: 'Satisfaction Score',
			dataKey: 'satisfaction_score',
			size: 6,
		},
		{
			name: 'Rating',
			dataKey: 'rating',
			size: 6,
		},
		{
			name: 'Performance Score',
			dataKey: 'performance_score',
			size: 6,
		},
		{
			name: 'Revisions',
			dataKey: 'revisions',
			size: 6,
		},
		{
			name: 'Code Quality',
			dataKey: 'code_quality',
			size: 6,
		},
		{
			name: 'CPU Used',
			dataKey: 'cpu_used',
			size: 6,
		},
		{
			name: 'Team Unit',
			dataKey: 'team_unit_name',
			size: 6,
		},
		{
			name: 'Libraries',
			dataKey: 'libraries',
			type: 'list',
			size: 12,
		},
		{
			name: 'Modified By',
			dataKey: 'modified_by',
			size: 12,
		},
	].filter(Boolean)

	const mobileheaders = [
		{
			name: 'Modelx',
			align: 'left',
			width: '100%',
			isMobileView: true,
			style: {
				// width: '100%',
				// maxWidth: '10vw',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				overflow: 'auto',
				textAlign: 'start',
			},
			textElementProps: {
				style: {
					whiteSpace: 'normal',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordBreak: 'break-word',
				},
			},
		},
	].filter(Boolean)

	const headers = [
		_.isEqual(_.get(appSettingsData, 'modelx_dashboard_config.enable_dashboard'), true) && {
			name: '',
			dataKey: 'model_id',
			align: 'center',
			width: '8%',
			isDashx: true,
			textElementProps: { style: { whiteSpace: 'normal' } },
		},
		{
			name: '',
			dataKey: 'model_id',
			align: 'center',
			width: '8%',
			isGlassx: true,
			textElementProps: { style: { whiteSpace: 'normal' } },
		},
		{
			name: ' Model Id',
			dataKey: 'model_id',
			align: 'center',
			width: '15%',
			textElementProps: { style: { whiteSpace: 'normal' } },
		},
		{
			name: 'Model Name',
			dataKey: 'model_name',
			align: 'center',
			width: '15%',
			style: {
				// width: '100%',
				// maxWidth: '10vw',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				// textAlign: 'start',
			},
			format: (object) => object.toUpperCase(),
			textElementProps: {
				style: {
					whiteSpace: 'normal',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordBreak: 'break-word',
				},
			},
		},
		{
			name: 'Version',
			dataKey: 'bot_version',
			width: '15%',
			align: 'center',
			style: { paddingLeft: 16 },
			textElementProps: {
				variant: 'body2',
				style: {
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				},
			},
		},
		{
			name: 'Developed By',
			dataKey: 'created_by',
			align: 'center',
			width: '15%',
			textElementProps: { style: { whiteSpace: 'normal' } },
		},
		{
			name: 'Status',
			dataKey: 'status',
			align: 'center',
			width: '15%',
			textElementProps: { style: { whiteSpace: 'normal' } },
		},
		{
			name: 'Modified By',
			dataKey: 'modified_by',
			align: 'center',
			width: '15%',
			textElementProps: { style: { whiteSpace: 'normal' } },
		},
		{
			name: 'Revisions',
			dataKey: 'revisions',
			align: 'center',
			width: '15%',
			textElementProps: { style: { whiteSpace: 'normal' } },
		},
		{
			name: 'Code Quality',
			dataKey: 'code_quality',
			align: 'center',
			width: '15%',
			textElementProps: { style: { whiteSpace: 'normal' } },
		},
		{
			name: 'Performance Score',
			dataKey: 'performance_score',
			align: 'center',
			width: '15%',
			textElementProps: { style: { whiteSpace: 'normal' } },
		},
		{
			name: 'Rating',
			dataKey: 'rating',
			align: 'center',
			width: '15%',
			textElementProps: { style: { whiteSpace: 'normal' } },
		},
		{
			name: 'Satisfaction Score',
			dataKey: 'satisfaction_score',
			align: 'center',
			width: '15%',
			textElementProps: { style: { whiteSpace: 'normal' } },
		},
	].filter(Boolean)

	const [isModalOpen, setIsModalOpen] = useState(false)
	const keyForCheckBoxSelectedList = 'model_id'

	const handleModalClose = () => setIsModalOpen(false)

	const dialogProps = {
		imgKey: 'image_slider',
		secImgKeys: '',
		title: 'product_name',
		subTitle: 'product_id',
		record: {},
		closeFxn: handleModalClose,
	}
	const [dialogPropsState, setDialogPropsState] = useState({ ...dialogProps })
	// const initialTime = () => {
	// 	return {
	// 		startDate: date_range?.startDate,
	// 		endDate: date_range?.endDate,
	// 	}
	// }
	// console.log(only_counterfeit, 'prod')
	const [pageQuery, setPageQuery] = useUrlSearchParams(
		{
			page: 1,
			limit: 12,
			// startDate: date_range?.startDate,
			// endDate: date_range?.endDate,
			filterParam: appliedFilters,
		},
		{
			page: Number,
			limit: Number,
			// startDate: Date,
			// endDate: Date,
			filterParam: String,
		}
	)
	const { page, limit, startDate, endDate } = pageQuery
	// const [timeStamp] = useState(() => setTimeParams(initialTime()))
	const [paginationFooterDetails, setPaginationFooterDetails] = useState({})
	const [exportList, setExportList] = useState([])
	const [allPageProduct, setAllPageProduct] = useState(false)
	const [slider, setSlider] = useState({ open: false, id: '', title: '' })

	const [openDashboardConfigForm, setDashboardConfigForm] = useState(false)
	// const [peoplexSettings, setPeoplexSettings] = useState({ peoplexSettingsLoading: false, peoplexDashboardConfig: [] })

	// const { peoplexSettingsLoading, peoplexDashboardConfig } = peoplexSettings

	const [type, setType] = useState('EXPORT SELECTED')
	const [state, setState] = useState({ loading: false, modelxList: [], totalCount: 0 })
	const { loading, modelxList, totalCount } = state

	const [isFilterChanged, setIsFilterChanged] = useState(false)
	const prevAppliedFilters = usePrevious(appliedFilters)

	let tempSelectedList = Object.assign([], exportList)
	let tempSelectedData = Object.assign([], tableData)

	const filterInitialState = {
		[FILTER_CONSTANTS.REDIRECT_URL]: false,
		[FILTER_CONSTANTS.SORT_BY]: null,
		[FILTER_CONSTANTS.SORT_BY_ORDER]: 'asc',
		[FILTER_CONSTANTS.CODE_QUALITY]: [0, 5],
		[FILTER_CONSTANTS.PERFORMANCE_SCORE]: [0, 5],
		[FILTER_CONSTANTS.CREATED_DATE]: {},
		[FILTER_CONSTANTS.REVISIONS]: [],
		[FILTER_CONSTANTS.STATUS]: [],
		[FILTER_CONSTANTS.RATING]: [0, 5],
		[FILTER_CONSTANTS.SATISFACTION_SCORE]: [0, 5],
		[FILTER_CONSTANTS.PROJECT_NAME]: [],
		[FILTER_CONSTANTS.GLASSX_NAME]: [],
		[FILTER_CONSTANTS.MODIFIED_BY]: [],
		[FILTER_CONSTANTS.CREATED_BY]: [],
		[FILTER_CONSTANTS.PERFORMANCE_TIME]: [],
	}

	useEffect(() => {
		if (sticky.showSnackbar === true && sticky.msgText === 'The task has been created successfully') {
			if (!_.isEmpty(finalQuery)) {
				if (cancelExecutor.current !== undefined) {
					cancelExecutor.current()
				}
				let redirectFilter = { ...filtersState, project_name: [redirectData?.project_title] }
				dispatch(
					getModelxTable(
						{ ...finalQuery, ds_id: dataSourceId },
						searchQuery,
						finalQuery?.redirect_url,
						isPickFrom,
						redirectFilter,
						redirectData,
						cancelExecutor
					)
				)
			}
		}
	}, [sticky])

	// useEffect(() => {
	// 	// dispatch(setThisFilter({startDate, endDate}, FILTER_CONSTANTS.DATE_RANGE));
	// 	handleTimeChange({ startDate, endDate })
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [startDate, endDate])

	// useEffect(() => {
	// 	setPageQuery({ ...pageQuery, ...date_range })
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [date_range])
	useEffect(() => {
		setPageQuery({ ...pageQuery, filterParam: appliedFilters })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appliedFilters])

	// const handleTimeChange = (dateObj) => {setTimeStamp(dateObj); setIsFilterChanged(true);};

	// const handleTimeChange = (dateObj) => {
	// 	dispatch(setThisFilter({ ...dateObj }, FILTER_CONSTANTS.DATE_RANGE))
	// 	setExportList([])
	// 	setTableData([])
	// 	setAllPageProduct(false)
	//     setSelectionMode(false)
	// }

	const noDataFetching = useRef(undefined)
	const firstTimeFetching = useRef(undefined)

	const cancelExecutor = useRef(undefined)

	const [finalQuery, setFinalQuery] = useState({})
	const prevFinalQuery = usePrevious(finalQuery)

	const dispatchTableDataFetching = () => {
		if (!_.isEmpty(finalQuery)) {
			if (cancelExecutor.current !== undefined) {
				cancelExecutor.current()
			}
			let redirectFilter = { ...filtersState, project_name: !_.isEmpty(redirectData?.project_title) ? [redirectData?.project_title] : [] }
			dispatch(
				getModelxTable(
					{ ...finalQuery, ds_id: dataSourceId },
					searchQuery,
					finalQuery?.redirect_url,
					isPickFrom,
					redirectFilter,
					redirectData,
					cancelExecutor
				)
			)
		}
	}

	const getModelxList = (params, query) => {
		setState({ loading: true, modelxList: [], totalCount: 0 })
		let redirectFilter = { ...filtersState, project_name: [redirectData?.project_title] }
		ModelxApi.getModelxTableData(
			params,
			query,
			finalQuery?.redirect_url,
			isPickFrom,
			redirectFilter,
			redirectData,
			cancelExecutor,
			(res) => {
				setState({ loading: false, modelxList: _.get(res, 'data.data.projects', []), totalCount: _.get(res, 'data.data.total_count', 0) })
			},
			(err) => {
				if (!Axios.isCancel(err)) setState({ loading: false, modelxList: [], totalCount: 0 })
			}
		)
	}

	useEffect(() => {
		// getModelxList({ ...finalQuery, ds_id: dataSourceId }, searchQuery)
		// dispatchTableDataFetching()
		// dispatch(getModelxTable({ ...finalQuery, ds_id: dataSourceId }, searchQuery, finalQuery?.redirect_url, cancelExecutor,
		// 	(res) => {
		// 		console.log('modelx res', res)
		// 		setState({ loading: false, modelxList: _.get(res, 'data.data.projects', []), totalCount: _.get(res, 'data.data.total_count', 0) })
		// 	},
		// 	(err) => {
		// 		if (!Axios.isCancel(err)) setState({ loading: false, modelxList: [], totalCount: 0 })
		// 	}))
	}, [])

	useEffect(() => {
		if (!_.isEqual(prevFinalQuery, finalQuery) && !_.isEmpty(finalQuery)) {
			dispatchTableDataFetching()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finalQuery, dispatchTableDataFetching, searchQuery])

	useEffect(() => {
		// console.log('appliedFilters :: ', appliedFilters);
		if (/* noDataFetching.current !== true && */ firstTimeFetching.current === 'done') {
			setFinalQuery({
				...appliedFilters,
				product_id: prodIDListForFilter,
				// ...timeStamp,
				...pageQuery,
			})
		}
		noDataFetching.current = undefined
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageQuery])

	useEffect(() => {
		if (isFilterChanged || firstTimeFetching.current === undefined) {
			noDataFetching.current = true
			if (firstTimeFetching.current === undefined) {
				noDataFetching.current = undefined
				firstTimeFetching.current = 'done'
			}
			setFinalQuery({
				...appliedFilters,
				product_id: searchQuery,
				// ...timeStamp,
				...pageQuery,
				page: 1,
			})
			setPageQuery({ ...pageQuery, page: 1 })
			setIsFilterChanged(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFilterChanged, searchQuery, pageQuery, page])

	// useEffect(()=>{
	//     console.log('setting date_range Filter :::: ', {...timeStamp});
	//     dispatch(setThisFilter({...timeStamp}, FILTER_CONSTANTS.DATE_RANGE))
	// }, [timeStamp]);

	// useEffect(()=>{
	//     if(_.toString(date_range?.startDate) !== _.toString(timeStamp?.startDate) && _.toString(date_range?.endDate) !== _.toString(timeStamp?.endDate)){
	//         setTimeStamp(setTimeParams(initialTime()));
	//         // return false
	//     }
	// }, [date_range])

	useEffect(() => {
		if (!_.isEqual(appliedFilters, prevAppliedFilters) && !_.isEmpty(prevAppliedFilters)) {
			// console.log('coz of filter')
			setIsFilterChanged(true)
		}
	}, [appliedFilters, prevAppliedFilters, setIsFilterChanged])

	const handleCheckBoxClick = (record) => {
		setAllPageProduct(false)
		// e.stopPropagation()
		const isAlreadyAvailable = _.indexOf(tempSelectedList, _.get(record, keyForCheckBoxSelectedList)) !== -1
		const isAllSelected = _.indexOf(tempSelectedList, 'all') !== -1
		if (!isAlreadyAvailable) {
			if (isAllSelected) {
				tempSelectedList = newProductsData.map((product) => product[keyForCheckBoxSelectedList])
				_.remove(tempSelectedList, (objectID) => objectID === _.get(record, keyForCheckBoxSelectedList))
				tempSelectedData = newProductsData.map((p) => p)
				_.remove(tempSelectedData, (p) => _.get(p, keyForCheckBoxSelectedList) === _.get(record, keyForCheckBoxSelectedList))
			} else {
				// if(_.isEmpty(_.get(record, keyForCheckBoxDisabling, ''))){
				tempSelectedList.push(_.get(record, keyForCheckBoxSelectedList))
				tempSelectedData.push(record)
				// }
				// else{
				//     dispatch(showSnackbarWithTimeout('Already assigned with a Job!', 3000))
				// }
			}
		} else if (isAlreadyAvailable) {
			_.remove(tempSelectedList, (objectID) => objectID === _.get(record, keyForCheckBoxSelectedList))
			_.remove(tempSelectedData, (p) => _.get(p, keyForCheckBoxSelectedList) === _.get(record, keyForCheckBoxSelectedList))
		}
		setExportList(tempSelectedList)
		setTableData(tempSelectedData)
	}

	//add sticky button click event
	const exportFxn = (exportList) => {
		// console.log(exportList, 'export')
		if (_.isEmpty(exportList)) {
			dispatch(showSnackbarWithTimeout('Please select some agents to add task', 3000))
		} else {
			openStickyDialog([], 'model', { modelx: tableData })
		}
	}

	const handleAllSelectCheckBoxClick = () => {
		if (newProductsData !== '-') {
			const isAllSelected = _.indexOf(tempSelectedList, 'all') !== -1
			const exportListLength = exportList.length
			if (isAllSelected) {
				setExportList([])
				setTableData([])
				setAllPageProduct(false)
				setSelectionMode(false)
			} else {
				if (exportListLength > 0) {
					setExportList([])
					setTableData([])
					setAllPageProduct(false)
					setSelectionMode(false)
				} else {
					setExportList(newProductsData.map((product) => product[keyForCheckBoxSelectedList]))
					setTableData(newProductsData)
					setAllPageProduct(false)
				}
			}
		}
	}

	const handleSelectCurrentPage = () => {
		if (newProductsData !== '-') {
			setExportList(newProductsData.map((product) => product[keyForCheckBoxSelectedList]))
			setTableData(newProductsData)
			setAllPageProduct(false)
		}
	}

	const handleSelectAllPageProducts = () => {
		if (newProductsData !== '-') {
			const isAllSelected = _.indexOf(tempSelectedList, 'all') !== -1
			const exportListLength = exportList.length
			setExportList(newProductsData.map((product) => product[keyForCheckBoxSelectedList]))
			setTableData(newProductsData)
			setAllPageProduct(true)
			setSelectionMode(true)
		}
	}

	const clearSelection = () => {
		setExportList([])
		setTableData([])
		setAllPageProduct(false)
		setSelectionMode(false)
	}

	const goToPage = (pageNo) => {
		setPageQuery({ ...pageQuery, page: pageNo })
		_.isEqual(allPageProduct, true) && clearSelection()
	}

	const setPageLimit = (selectedLimit) => {
		setSortSettings({ keyToSort: '', sortDirection: true })
		setPageQuery({ ...pageQuery, limit: selectedLimit, page: 1 })
	}

	const paginationProperties = (pageNo, limit, totalCount, selectedList) => {
		return getDetailedPaginationProperties(limit, totalCount, pageNo, selectedList)
	}

	useEffect(() => {
		setExportList([])
		setSelectionMode(false)
		setPageQuery({ ...pageQuery, page: 1 })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortSettings])

	// const getTableData = () => {
	//     // setPageQuery({...pageQuery, page: 1, limit : 10, ...timeStamp, sort: sortFilter(sortSettings)})
	//     // dispatch(getModelxTable(params))
	//     // dispatch(getClientId());
	//     // dispatch(getBpId());

	// }
	// useEffect( getTableData , [])

	const wholeData = useSelector((state) => state.modelxTable.modelx_list)
	const newProductsData = _.get(wholeData, 'chart_data', '-')
	const totalData = _.get(wholeData, 'data_count', '-')
	const isFetchingNewProducts = useSelector((state) => state.modelxTable.isFetchingModelxList)
	// const isErrorFetchingNewProducts = useSelector(state => state.productsTable.isFetchingModelxListFailed);

	useEffect(() => {
		if (wholeData !== 'undefined') {
			setPaginationFooterDetails({
				...paginationProperties(page, limit, _.get(wholeData, 'data_count', ''), exportList),
			})
		}
	}, [wholeData, exportList, limit, page])

	// const ReduxState = useSelector((state) => state)

	const isFilterActive = useMemo(() => {
		const initialFilter = _.omit(filterInitialState, ['sort_by', 'sort_by_order', 'redirect_url'])
		const filter = _.omit(filtersState, ['sort_by', 'sort_by_order', 'redirect_url'])
		const value = !_.isEqual(initialFilter, filter)
		// console.log('modelx isFilterActive', initialFilter, filter, value, ReduxState)
		return value
	}, [filtersState])

	const switchClassesProp = {
		root: classes.root,
		switchBase: classes.switchBase,
		thumb: classes.thumb,
		track: classes.track,
		checked: classes.checked,
		// focusVisible : classes.focusVisible
	}

	// function formatDateForLabel(dateString) {
	// 	let dt = new Date(dateString)
	// 	let today = new Date()
	// 	let todayStr = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
	// 	let yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
	// 	let yesterdayStr = yesterday.getDate() + '/' + (yesterday.getMonth() + 1) + '/' + yesterday.getFullYear()
	// 	let dtStr = dt.getDate() + '/' + (dt.getMonth() + 1) + '/' + dt.getFullYear()
	// 	//  console.log("YESTERDAY", yesterdayStr, yesterday)
	// 	//  console.log("GIVEN DATE", dtStr, dt);
	// 	if (_.isEqual(todayStr, dtStr)) {
	// 		return 'Today'
	// 	} else if (_.isEqual(yesterdayStr, dtStr)) {
	// 		return 'Yesterday'
	// 	} else {
	// 		return format(_.isString(dt) ? new Date(dt) : dt, 'dd/MM/yyyy')
	// 	}
	// }

	const onClickDeleteMenu = (idx) => {
		dispatch(
			showConfirmMessage(
				`Are you sure, you want to delete this Model?`,
				'',
				'Confirm',
				(e) => {
					dispatch(showLoader('Please wait...'))
					ModelxApi.deleteModelx(
						idx,
						(res) => {
							dispatch(hideLoader())
							handleCloseSlider()
							dispatchTableDataFetching()
							// getProjectList('', 0, 12)
							// setParams((prevState) => ({
							// 	...prevState,
							// 	limit: 12,
							// }))
							dispatch(showSnackbarWithTimeout(_.get(res, 'data.more_info', 'Something went wrong. Please try again!'), 2000))
						},
						(err) => {
							dispatch(hideLoader())
							handleCloseSlider()
							dispatch(
								showErrorMessage(_.get(err, 'response.data.more_info', 'Something went wrong. Please try again!'), 'Close', () => {
									dispatch(hideLoader())
								})
							)
						}
					)
				},
				'Cancel',
				(e) => {
					dispatch(hideLoader())
				}
			)
		)
	}

	const modelDashxEnabled = _.isEqual(_.get(appSettingsData, 'modelx_dashboard_config.enable_dashboard'), true)

	const onClickDashX = (e, record, recordIdx) => {
		modelDashxEnabled &&
			openDashboard(
				_.get(appSettingsData, 'modelx_dashboard_config.dashboard_id.dashboard_id', ''),
				{
					title: _.get(record, `${_.get(appSettingsData, 'modelx_dashboard_config.field_name', '')}`),
					filterColumn: _.get(appSettingsData, 'modelx_dashboard_config.field_name', ''),
					filterValue: _.toString(_.get(record, `${_.get(appSettingsData, 'modelx_dashboard_config.field_name', '')}`)),
					dsId: _.get(appSettingsData, 'modelx_dashboard_config.ds_id.ds_id', ''),
					history: history,
					subtitle: _.get(appSettingsData, 'modelx_dashboard_config.ds_id.ds_name', ''),
					tag: 'People',
					viewLink: null,
				},
				null,
				false,
				() => {
					closeDashboard()
				}
			)
	}

	const onClickGlassX = (e, record, recordIdx) => {
		const glassCode = record?.glass_code
		const stickyCode = record?.model_id

		if (isPickerMode && setOpenModel) {
			setOpenModel(false)
		} else {
			history.push(`/glassx/view/${glassCode}/${stickyCode}`)
		}
	}

	const onClickDashboardConfig = () => {
		setDashboardConfigForm(true)
	}

	const renderSelectionSummary = () => {
		let summaryString = ''
		if (exportList.length === 0) {
			summaryString = '-'
		} else if (allPageProduct) {
			summaryString = `${_.get(paginationFooterDetails, 'total', 0)} items selected.`
		} else {
			if (_.indexOf(exportList, 'all') === -1 && exportList.length > 0) {
				summaryString = `${exportList.length} item${exportList.length === 1 ? '' : 's'} ${exportList.length === 1 ? 'is' : 'are'} selected.`
			} else if (_.indexOf(exportList, 'all') >= 0) {
				summaryString = `${_.get(paginationFooterDetails, 'total', 0)} items selected.`
			}
		}
		return summaryString
	}

	const navigateToNextPage = () => {
		goToPage(parseInt(page) + 1)
	}

	const navigateToPrevPage = () => {
		goToPage(parseInt(page) - 1)
	}

	const navigateToLastPage = () => {
		goToPage(parseInt(_.last(_.get(paginationFooterDetails, 'paginationNumbers'))))
	}

	const navigateToFirstPage = () => {
		goToPage(parseInt(_.first(_.get(paginationFooterDetails, 'paginationNumbers'))))
	}

	const OnTextChange = (e) => {
		let value = parseInt(e.target.value)
		let compare =
			parseInt(e.target.value) > _.last(_.get(paginationFooterDetails, 'paginationNumbers')) ||
			parseInt(e.target.value) < _.first(_.get(paginationFooterDetails, 'paginationNumbers'))
		setTimeout(() => {
			if (_.isNaN(value) || compare) {
				goToPage(1)
			} else {
				goToPage(value)
			}
		}, 1500)
	}

	const closeFilterDialog = () => {
		setMenuProps(false)
	}

	const handleCloseSlider = () => {
		setSlider({ open: false, id: '', title: '' })
	}

	const handleSliderOpen = (record) => {
		setSlider({ open: true, id: record[keyForCheckBoxSelectedList], title: record['model_name'] })
	}

	const [openExportDialog, setOpenExportDialog] = useState(false)

	const exportSearchParams = useMemo(
		() => ({
			search_text: searchQuery,
			limit: 12,
			offset: 0,
		}),
		[searchQuery]
	)

	const toggleExportDialog = () => {
		setOpenExportDialog(!openExportDialog)
	}

	const onClickExport = () => {
		if (_.isEmpty(exportList)) {
			dispatch(showSnackbarWithTimeout('Please select some agents to export', 3000))
		} else {
			toggleExportDialog()
		}
	}

	return (
		<>
			<div className='m-16'>
				<Grid
					container
					spacing={0}
					className={classnames(
						'container-no-fluid',
						isPickerMode ? classes.pickerPageContent : 'page-content',
						classes.containerRoot,
						classes.rootContainer
					)}
				>
					<Grid item xs={12}>
						{/* <PageHeader
						title={'Models'}
						titleIcon='psychology_icon'
						onChangeSearch={handleSearchInputChange}
						searchQuery={searchQuery}
						actions={isExportAllowed ? <PrimaryButton onClick={onClickExport}>Export Selected</PrimaryButton> : null}
					></PageHeader> */}
						<PageHeaderSort
							title={'Agents'}
							titleIcon='psychology_icon'
							actions={isExportAllowed && !isPickerMode ? <PrimaryButton onClick={onClickExport}>Export Selected</PrimaryButton> : null}
							onChangeSearch={handleSearchInputChange}
							sortable_fields={sortableFields}
							sortingDetails={sortingDetails}
							setSortingDetails={setSortingDetails}
							sortFilter={sortFilter}
						></PageHeaderSort>
					</Grid>
					<ExportDialog
						open={openExportDialog}
						handleClose={toggleExportDialog}
						searchParams={exportSearchParams}
						// data={selectedResultsIds}
						isSelectedAll={allPageProduct}
						exportList={tableData}
						pageType={'modelx'}
					/>
					<Grid item xs={12}>
						<TableDeskBody
							data={newProductsData !== '-' && newProductsData}
							isLoading={isFetchingNewProducts}
							handleCheckBoxClick={handleCheckBoxClick}
							exportList={exportList}
							renderSelectionSummary={renderSelectionSummary}
							keyForCheckBoxSelectedList={keyForCheckBoxSelectedList}
							paginationFooterDetails={paginationFooterDetails}
							// handleSelectAllProducts={handleSelectAllProducts}
							isSelectMode={!isPickerMode}
							clearSelection={clearSelection}
							type={type}
							pageType={'modelx'}
							handleSelectAllPageProducts={handleSelectAllPageProducts}
							allPageProduct={allPageProduct}
							setExportList={setExportList}
							selectionMode={selectionMode}
							setSelectionMode={setSelectionMode}
							onClickDashX={onClickDashX}
							onClickGlassX={onClickGlassX}
							mobileHeaderKeys={mobileHeaderKeys}
							mobileContent={mobileContent}
							// flagStatus={flagStatus}
							handleSelectCurrentPage={handleSelectCurrentPage}
							history={history}
							handleSliderOpen={handleSliderOpen}
							disableDashxBtn={!modelDashxEnabled}
						/>
					</Grid>
					<Grid item xs={12}>
						{!_.isEmpty(newProductsData !== '-' && newProductsData) && !_.isEmpty(_.get(paginationFooterDetails, 'paginationNumbers')) && (
							<Grid container className={classes.paginationContainer}>
								<Grid item xs={4} sm={2} container className={classes.viewSummaryContainer} justifyContent='flex-start'>
									<Typography variant='body2'>{_.get(paginationFooterDetails, 'viewSummary', '')}</Typography>
								</Grid>
								<Grid item xs={4} sm={10} className={classes.pageLinksContainer}>
									<div className={classes.pagination}>
										<IconButton
											classes={{
												root: classes.muiIconButtonRoot,
												disabled: classes.muiIconButtonDisabled,
											}}
											disableRipple
											onClick={navigateToFirstPage}
											disabled={!_.get(paginationFooterDetails, 'isPrevAvail')}
										>
											<Icon>keyboard_double_arrow_left</Icon>
										</IconButton>
										<IconButton
											classes={{
												root: classes.muiIconButtonRoot,
												disabled: classes.muiIconButtonDisabled,
											}}
											disableRipple
											onClick={navigateToPrevPage}
											disabled={!_.get(paginationFooterDetails, 'isPrevAvail')}
										>
											<Icon>chevron_left</Icon>
										</IconButton>
										{smallScreen ? (
											<Button
												key={page}
												classes={{ root: classes.muiButtonRoot }}
												onClick={() => {
													goToPage(page)
												}}
												className={classnames({
													active: page === parseInt(page),
												})}
												disableRipple
											>
												{page}
											</Button>
										) : (
											_.get(paginationFooterDetails, 'paginationNumbers').map((element, idx) => {
												if (typeof element === 'number')
													return (
														<Button
															key={element}
															classes={{ root: classes.muiButtonRoot }}
															onClick={() => {
																goToPage(element)
															}}
															className={classnames({
																active: element === parseInt(page),
															})}
															disableRipple
														>
															{element}
														</Button>
													)
												else
													return (
														<div key={element + idx} className={classes.threeDotsDiv}>
															<span key={`${idx}-dot`}>{element}</span>
														</div>
													)
											})
										)}
										<IconButton
											classes={{
												root: classes.muiIconButtonRoot,
												disabled: classes.muiIconButtonDisabled,
											}}
											disableRipple
											onClick={navigateToNextPage}
											disabled={!_.get(paginationFooterDetails, 'isNextAvail')}
										>
											<Icon>chevron_right</Icon>
										</IconButton>
										<IconButton
											classes={{
												root: classes.muiIconButtonRoot,
												disabled: classes.muiIconButtonDisabled,
											}}
											disableRipple
											onClick={navigateToLastPage}
											disabled={!_.get(paginationFooterDetails, 'isNextAvail')}
										>
											<Icon>keyboard_double_arrow_right</Icon>
										</IconButton>
									</div>
									<div className={classes.pagination}>
										<TextField size='small' className={classes.pageInput} defaultValue={parseInt(page)} onChange={(e) => OnTextChange(e)} />
										<Typography variant='body1' style={{ paddingRight: 10 }}>
											of {_.get(paginationFooterDetails, 'endPageNo', '')}
										</Typography>
										<Select
											value={limit}
											classes={{
												outlined: classes.muiSelectOutlined,
												iconOutlined: classes.muiSelectIconOutlined,
											}}
											variant='outlined'
											onChange={(e) => setPageLimit(e.target.value)}
										>
											<MenuItem value={12}>12</MenuItem>
											<MenuItem value={24}>24</MenuItem>
											<MenuItem value={48}>48</MenuItem>
										</Select>
									</div>
								</Grid>
							</Grid>
						)}
					</Grid>

					<Slider
						match={match}
						slider={slider}
						onClose={handleCloseSlider}
						onClickDeleteMenu={onClickDeleteMenu}
						hideDeleteBtn={!isDeleteAllowed}
						editAllowed={isEditAllowed}
						// glassCode={glassCode}
						// sessionId={sessionId}
						// glassLabels={glassLabels}
						// glassMembers={glassMembers}
						// isOwner={isOwner}
						// isMember={isMember}
						// activityTypes={activityTypes}
						// socketRef={socketIO}
						// isSocketConnected={isSocketConnected}
						// authToken={authToken}
						history={history}
						onClickDashX={onClickDashX}
						onClickGlassX={onClickGlassX}
						disableDashxBtn={!modelDashxEnabled}
						// isGuest={isGuest}
						// lanesData={lanesData}
						// location={location}
						// glassStickyGroups={glassStickyGroups}
					/>

					<Grow in={!filterMenuProps.isOpen} unmountOnExit>
						<Fab
							className={classes.fab}
							color='primary'
							size='small'
							onClick={() => {
								setMenuProps((prev) => ({
									...prev,
									isOpen: true,
								}))
							}}
						>
							{isFilterActive ? (
								<Badge color='error' badgeContent={''} variant='dot'>
									<Icon>filter_list</Icon>
								</Badge>
							) : (
								<Icon>filter_list</Icon>
							)}
						</Fab>
					</Grow>

					<div
						className={classnames(
							classes.menuContainer,
							{ [classes.openMenu]: filterMenuProps.isOpen },
							{ [classes.closeMenu]: !filterMenuProps.isOpen }
						)}
					>
						<ModelxNewFilters
							isPickerMode={isPickerMode}
							isPickFrom={isPickFrom}
							data={redirectData}
							onCloseDialog={closeFilterDialog}
							isSmallDevice={isSmallDevice}
							noScrapperFilter={''}
							filterOpen={filterMenuProps.isOpen}
							showTheseFilters={[
								FILTER_CONSTANTS.CREATED_DATE,
								FILTER_CONSTANTS.PROJECT_NAME,
								FILTER_CONSTANTS.GLASSX_NAME,
								FILTER_CONSTANTS.CREATED_BY,
								FILTER_CONSTANTS.MODIFIED_BY,
								FILTER_CONSTANTS.REVISIONS,
								FILTER_CONSTANTS.STATUS,
								FILTER_CONSTANTS.RATING,
								FILTER_CONSTANTS.CODE_QUALITY,
								FILTER_CONSTANTS.PERFORMANCE_SCORE,
								FILTER_CONSTANTS.PERFORMANCE_TIME,
								FILTER_CONSTANTS.SATISFACTION_SCORE,
							]}
							extraFilterProps={''}
							setExportList={setExportList}
							setTableData={setTableData}
							setAllPageProduct={setAllPageProduct}
							searchSuggestionsProductType='new'
							filterInitialState={filterInitialState}
						/>
					</div>
				</Grid>
			</div>

			{/* <Layout
				headers={headers}
				mobileheaders={mobileheaders}
				mobileHeaderKeys={mobileHeaderKeys}
				mobileContent={mobileContent}
				layoutTitle='ModelX'
				layoutTitleIcon='psychology_icon'
				exportBtnTxt={'Export'}
				buttonText={TASK_BUTTON_TXT}
                peoplexFilter={true}
				buttonIcon={<Icon>sticky_note_2</Icon>}
				hideButton={!isTaskAddAllowed}
				handleSearchInputChange={handleSearchInputChange}
				initialTime={date_range}
				handleTimeChange={handleTimeChange}
				isViewAllowed={isViewAllowed}
				isCreateAllowed={isCreateAllowed}
				isEditAllowed={isEditAllowed}
				isDeleteAllowed={isDeleteAllowed}
				// {...(width === 'xs' ? {} : { initialTime: date_range, handleTimeChange: handleTimeChange })}
				page={page}
				limit={limit}
				pageType={'modelx'}
				searchSuggestionsProductType='new'
				setPageLimit={setPageLimit}
				goToPage={goToPage}
				paginationFooterDetails={paginationFooterDetails}
				isLoading={isFetchingNewProducts}
				isCheckBoxNeeded={true}
				keyForCheckBoxSelectedList={keyForCheckBoxSelectedList}
				// keyForCheckBoxDisabling={keyForCheckBoxDisabling}
				onCheckBoxClick={onCheckBoxClick}
				handleCheckBoxClick={handleCheckBoxClick}
				handleAllSelectCheckBoxClick={handleAllSelectCheckBoxClick}
				handleSelectCurrentPage={handleSelectCurrentPage}
				handleSelectAllPageProducts={handleSelectAllPageProducts}
				setExportList={setExportList}
				setTableData={setTableData}
				setAllPageProduct={setAllPageProduct}
				allPageProduct={allPageProduct}
				setType={setType}
				selectedData={tableData}
				searchQuery={searchQuery}
				exportList={exportList}
				exportFxn={exportFxn}
				onClickDashX={_.isEqual(_.get(appSettingsData, 'modelx_dashboard_config.enable_dashboard'), true) && onClickDashX}
				onClickGlassX={onClickGlassX}
				onClickDashboardConfig={onClickDashboardConfig}
				selectionMode={selectionMode}
				setSelectionMode={setSelectionMode}
				fixedLayout
				groupDataBy='date'
				fxnToFormatGroupByKey={(key) => formatDateForLabel(key)}
				// sortSettings={sortSettings}
				enableBorderBottom
				clearSelection={clearSelection}
				// handleSelectAllProducts={handleSelectAllProducts}
				// newFilters={true}
				showTheseFilters={[
					// ...(width === 'xs' ? [FILTER_CONSTANTS.DATE_RANGE] : []),
                    // FILTER_CONSTANTS.DISCOUNT,
					FILTER_CONSTANTS.REVISIONS,
					FILTER_CONSTANTS.CODE_QUALITY,
					FILTER_CONSTANTS.PERFORMANCE_SCORE,
					FILTER_CONSTANTS.STATUS,
					FILTER_CONSTANTS.RATING,
					FILTER_CONSTANTS.SATISFACTION_SCORE,
					FILTER_CONSTANTS.PROJECT_NAME,
					FILTER_CONSTANTS.GLASSX_NAME,
					FILTER_CONSTANTS.MODIFIED_BY,
					FILTER_CONSTANTS.CREATED_BY,
                    FILTER_CONSTANTS.PERFORMANCE_TIME,
				]}
				// showTheseFilters={['discount', 'product','retailer','stock', 'reseller']}
				data={newProductsData !== '-' && newProductsData}
				totalData={totalData}
			/> */}

			<ImagePopUp isOpen={isModalOpen} imgProps={dialogPropsState} />
		</>
	)
}

export default compose(withAddTaskDialog, withDashboardModal, withShareDialog)(Modelx)
