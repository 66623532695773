import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Grid, Tab, Tabs, Typography, makeStyles } from '@material-ui/core'
import _ from 'lodash'
import TabPanel from 'components/TabPanel'
import { useUrlSearchParams } from 'hooks/use-url-search-params'
import PropTypes from 'prop-types'
import BasicDetails from './BasicDetails'
import ModelxLogs from './ModelxLogs'

import { useDispatch, useSelector } from 'react-redux'
import { getModelxDetails } from '../../../../services/modelx/actions'

const defaultTabId = 'info'

const useStyles = makeStyles((theme) => ({
	tabs: {
		minHeight: 'unset',
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	tab: {
		minHeight: 'unset',
		textTransform: 'unset',
	},
}))

function StickyTabs({
	isOwner,
	glassCode,
	glassMembers,
	glassStickyGroups,
	glassLabels,
	sessionId,
	formik,
	onAddAttachments,
	updateStickyDetails,
	stickyDetails,
	loading,
	isEditEnabled,
	isGuest,
	attachmentEditEnabled,
	stickyId,
	attachmentLoading,
	onDeleteAttachment,
	onDownloadAttachment,
	activityTypes,
	socketRef,
	isSocketConnected,
	isOwnerOrReporter,
	isTimerEnabled,
	onSubmitLinkData,
	onCreateSubtask,
	isMember,
	history,
    slider,
    basicDetails,
	disableDashxBtn,
	onClickDashX,
	onClickGlassX,
}) {
	const classes = useStyles()
	// const [pageQuery, setPageQuery] = useUrlSearchParams({}, { tabId: String })
    const [tabId, setTabId] = useState(defaultTabId)
	const stickyTabs = useMemo(
		() =>
			_.compact([
				{
					id: 'info',
					label: 'Agent Info',
					tabPanel: BasicDetails,
				},
				{
					id: 'log',
					label: 'Agent Logs',
					tabPanel: ModelxLogs,
				},
			]),
		[]
	)

	// const activeTabIdx = useMemo(() => _.findIndex(stickyTabs, { id: _.get(pageQuery, 'tabId') || defaultTabId }), [pageQuery, stickyTabs])

    const activeTabIdx = useMemo(() => _.findIndex(stickyTabs, { id: tabId }), [tabId])


	const onChangeTab = (e, tabIdx) => {
		// setPageQuery({ tabId: _.get(stickyTabs, `[${tabIdx}].id`) }, true)
        setTabId(_.get(stickyTabs, `[${tabIdx}].id`))
	}



    // const dispatch = useDispatch()

    // const cancelExecutor = useRef()

    // const basicDetails = useSelector((state) => state.modelxTable?.modelx_details)

    // useEffect(() => {
    //     // if (cancelExecutor.current !== undefined) {
    //     //     cancelExecutor.current()
    //     // }
    //     dispatch(getModelxDetails(slider?.id, cancelExecutor))
    // }, [])

	return (
		<Grid container>
			<Grid item xs={12}>
				<Tabs
					variant='scrollable'
					className={classes.tabs}
					size='small'
					value={activeTabIdx}
					indicatorColor='primary'
					textColor='primary'
					onChange={onChangeTab}
					aria-label='disabled tabs example'
				>
					{_.map(stickyTabs, (tab, index) => (
						<Tab key={tab.id} className={classes.tab} label={<Typography variant='body2'>{tab.label}</Typography>} />
					))}
				</Tabs>
			</Grid>
			<Grid item xs={12}>
				{_.map(stickyTabs, (tab, index) => (
					<TabPanel key={tab.id} label={tab.label} value={activeTabIdx} index={index}>
						{React.createElement(tab.tabPanel, {
							isOwner,
							glassCode,
							glassMembers,
							glassStickyGroups,
							isMember,
							glassLabels,
							sessionId,
							formik,
							stickyDetails,
							isEditEnabled,
							isGuest,
							attachmentEditEnabled,
							loading,
							stickyId,
							onAddAttachments,
							attachmentLoading,
							onDeleteAttachment,
							onDownloadAttachment,
							updateStickyDetails,
							activityTypes,
							socketRef,
							isSocketConnected,
							isOwnerOrReporter,
							isTimerEnabled,
							onSubmitLinkData,
							onCreateSubtask,
							history,
                            slider,
                            basicDetails,
							disableDashxBtn,
							onClickDashX,
							onClickGlassX,
						})}
					</TabPanel>
				))}
			</Grid>
		</Grid>
	)
}

export default StickyTabs
