import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
	makeStyles,
	Grid,
	Typography,
	Select,
	MenuItem,
	MenuList,
	Button,
	Icon,
	IconButton,
	ClickAwayListener,
	ButtonGroup,
	useMediaQuery,
	useTheme,
	Fab,
	Popper,
	Popover,
	Grow,
	Paper,
	Dialog,
} from '@material-ui/core'
import Filters from '../Filters'
import NewFilters from '../FiltersNew'
import PeoplexNewFilters from '../PeoplexFiltersNew'
import ModelxNewFilters from '../ModelxFiltersNew'
import ProductTable from '../ProductTable'
import { TIME_FILTERS } from '../../constants'
import _ from 'lodash'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'
import { defaultStaticRanges, defaultInputRanges } from '../../utils/defaultDateRanges'
import { format, isSameDay } from 'date-fns'
import FullScreenDialog from 'components/FullScreenDialog'
import { useDispatch } from 'react-redux'
import { clearAllFilters } from 'services/filters/actions'
import { clearSuggestions } from 'services/searchSuggestions/actions'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ExportDialog from '../ExportDialog'
import { showSnackbarWithTimeout } from '../../services/snackbar/actions'
import classnames from 'classnames'
import PrimaryButton from '../../components/PrimaryButton'
import CustomPopoverMenus from 'components/CustomPopoverMenus'
import {
	addDays,
	subDays,
	endOfDay,
	startOfDay,
	startOfMonth,
	endOfMonth,
	addMonths,
	startOfWeek,
	endOfWeek,
	startOfYear,
	endOfYear,
	addYears,
} from 'date-fns'

const useStyles = makeStyles((theme) => ({
	layout: {
		width: '100%',
		minHeight: '100vh',
		height: 'calc(100vh - 0px)',
		paddingTop: 64, //header-height
		// maxHeight: '100%',
		// paddingTop: '65px',
		'& .MuiOutlinedInput-root': {
			marginLeft: '12px',
		},
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			borderRadius: '4px',
			border: `1px solid ${theme.palette.almostBlack[300]}`,
		},
		'& .MuiOutlinedInput-root:hover': {
			'&  .MuiOutlinedInput-notchedOutline': {
				borderRadius: '4px',
				border: `1px solid ${theme.palette.almostBlack[300]}`,
			},
			'& .MuiSelect-root': {
				background: `rgba(0,0,0, 0.04)`,
				transition: `background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
			},
		},
	},
	pickerLayout: {
		width: '100%',
		minHeight: '100vh',
		height: 'calc(100vh - 0px)',
		paddingTop: 2, //header-height
		// maxHeight: '100%',
		// paddingTop: '65px',
		'& .MuiOutlinedInput-root': {
			marginLeft: '12px',
		},
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			borderRadius: '4px',
			border: `1px solid ${theme.palette.almostBlack[300]}`,
		},
		'& .MuiOutlinedInput-root:hover': {
			'&  .MuiOutlinedInput-notchedOutline': {
				borderRadius: '4px',
				border: `1px solid ${theme.palette.almostBlack[300]}`,
			},
			'& .MuiSelect-root': {
				background: `rgba(0,0,0, 0.04)`,
				transition: `background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
			},
		},
	},
	h1: {
		fontSize: theme.typography.pxToRem(48),
	},
	filterSection: {
		padding: theme.spacing(2, 3, 2, 2),
		boxSizing: 'border-box',
		borderRight: `1px solid rgba(158, 156, 156, 0.23)`,
		background: theme.palette.almostBlack[0],
		height: '100%',
		overflowY: 'auto',
		// minWidth: '260px',
		maxWidth: '300px',
		'&::-webkit-scrollbar,::-webkit-scrollbar-track': {
			width: '10px',
			background: 'transparent',
		},
		'&::-webkit-scrollbar-thumb': {
			background: theme.palette.almostBlack[400],
			height: '10px',
		},
		[theme.breakpoints.down('1280')]: {
			padding: theme.spacing(3),
			maxWidth: 'unset',
		},
	},
	contentSection: {
		padding: theme.spacing(4),
		height: '100%',
		boxSizing: 'border-box',
		overflowX: 'hidden',
		overflowY: 'auto',
		'&::-webkit-scrollbar,::-webkit-scrollbar-track': {
			width: '10px',
			background: 'transparent',
		},
		'&::-webkit-scrollbar-thumb': {
			background: theme.palette.almostBlack[400],
			height: '10px',
		},
		[theme.breakpoints.down('1280')]: {
			padding: theme.spacing(2),
		},
	},
	muiSelectOutlined: {
		fontSize: theme.typography.pxToRem(14),
		color: theme.palette.almostBlack[600],
		fontFamily: ['"Roboto"', '"sans-serif"'].join(','),
		letterSpacing: theme.typography.pxToRem(0.1),
		padding: `7px`,
		paddingRight: `${theme.spacing(4.5)}px !important`,
		border: `1px solid ${theme.palette.almostBlack[300]}`,
		borderRadius: `8px`,
		outline: 0,
		background: theme.palette.almostBlack[0],
		'&::after': {
			content: '" "',
			position: 'absolute',
			height: '94%',
			border: `1px solid ${theme.palette.almostBlack[300]}`,
			top: '0px',
			right: '26px',
		},
		'&.MuiSelect-select:focus': {
			borderRadius: '8px',
			background: theme.palette.almostBlack[0],
		},
	},
	muiSelectIconOutlined: {
		right: `3px`,
	},
	title: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
	},
	tableTitleContainer: {
		// paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(3),
		[theme.breakpoints.down('1280')]: {
			paddingBottom: 0,
		},
	},
	layoutTitleIcon: {
		fontSize: theme.typography.pxToRem(22),
		color: theme.palette.almostBlack[700],
		marginRight: theme.spacing(1.5),
		[theme.breakpoints.down('1280')]: {
			fontSize: theme.typography.pxToRem(20),
		},
	},
	muiButtonRoot: {
		// height: '32px',
		// borderRadius: '10px',
		// marginLeft: theme.spacing(2),
		// padding: theme.spacing(0, 1.5),
		'& h6': {
			fontWeight: 600,
			textTransform: 'capitalize',
			lineHeight: theme.typography.pxToRem(14),
			fontSize: theme.typography.pxToRem(14),
			marginLeft: theme.spacing(0.5),
		},
	},
	headerFiltersSection: {
		gap: `16px`,
		[theme.breakpoints.down('766')]: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-end',
			gap: `8px`,
		},
		[theme.breakpoints.down('380')]: {
			paddingTop: '8px',
		},
	},
	calendarContainer: {
		// maxWidth: 230,
		width: 'auto',
		position: 'relative',
		'& .MuiIcon-root': {
			fontSize: theme.typography.pxToRem(16),
		},
		'& .MuiButtonGroup-root': {
			minHeight: 30,
			'& .MuiButton-outlined': {
				// height: '32px',
				background: theme.palette.almostBlack[0],
				// color: theme.palette.almostBlack[800]
			},
			'& .MuiButton-outlined:first-child': {
				// borderRadius: '10px 0px 0px 10px',
				color: theme.palette.almostBlack[800],
			},
			'& .MuiButton-outlined:last-child': {
				// borderRadius: '0px 10px 10px 0px',
			},
			'& .MuiButton-outlined:only-child': {
				// borderRadius: '10px',
			},
		},
	},
	calendarWrapper: {
		// position: 'absolute',
		// top: '100%',
		// right: '0%',
		// zIndex: 5,
		padding: theme.spacing(2),
		background: theme.palette.almostBlack[100],
		boxShadow: `0px 0px 5px 0px ${theme.palette.almostBlack[400]}`,
		borderRadius: '10px',
		'& .rdrDefinedRangesWrapper': {
			borderRadius: '10px 0px 0px 10px',
			'& .rdrStaticRanges': {
				borderRadius: '10px 0px 0px 0px',
				'& .rdrStaticRange:first-child, .rdrStaticRange:first-child:hover': {
					borderRadius: '10px 0px 0px 0px',
					'& .rdrStaticRangeLabel': {
						borderRadius: '10px 0px 0px 0px',
					},
				},
			},
		},
		'& .rdrCalendarWrapper': {
			borderRadius: '0px 10px 10px 0px',
			'& .rdrDateDisplayWrapper': {
				borderRadius: '0px 10px 0px 0px',
			},
		},
	},
	// calendarWrapper: {
	// 	// position: 'absolute',
	// 	// top: '100%',
	// 	// right: '0%',
	// 	// zIndex: 5,
	// 	padding: theme.spacing(2),
	// 	background: theme.palette.almostBlack[100],
	// 	boxShadow: `0px 0px 5px 0px ${theme.palette.almostBlack[400]}`,
	// 	borderRadius: '10px',
	// 	[theme.breakpoints.down('796')]: {
	// 		padding: 0,
	// 	},
	// 	'& .rdrDefinedRangesWrapper': {
	// 		borderRadius: '10px 0px 0px 10px',
	// 		[theme.breakpoints.between('565', '618')]: {
	// 			width: '174px',
	// 		},
	// 		[theme.breakpoints.between('400', '565')]: {
	// 			width: '128px',
	// 		},
	// 		[theme.breakpoints.between('326', '400')]: {
	// 			width: '84px',
	// 		},
	// 		[theme.breakpoints.between('309', '326')]: {
	// 			width: '78px',
	// 		},
	// 		[theme.breakpoints.down('309')]: {
	// 			width: '70px',
	// 		},
	// 		'& .rdrStaticRanges': {
	// 			borderRadius: '10px 0px 0px 0px',
	// 			[theme.breakpoints.down('565')]: {
	// 				alignItems: 'center',
	// 			},
	// 			'& .rdrStaticRange': {
	// 				'& .rdrStaticRangeLabel': {
	// 					[theme.breakpoints.between('309', '400')]: {
	// 						padding: '10px 7px',
	// 					},
	// 					[theme.breakpoints.down('309')]: {
	// 						padding: '10px 1px',
	// 					},
	// 				},
	// 			},
	// 			'& .rdrStaticRange:first-child, .rdrStaticRange:first-child:hover': {
	// 				borderRadius: '10px 0px 0px 0px',
	// 				'& .rdrStaticRangeLabel': {
	// 					borderRadius: '10px 0px 0px 0px',
	// 				},
	// 			},
	// 		},
	// 		'& .rdrInputRanges': {
	// 			[theme.breakpoints.down('400')]: {
	// 				padding: '3px 0px',
	// 			},
	// 			'& .rdrInputRange': {
	// 				textAlign: 'center',
	// 				[theme.breakpoints.down('565')]: {
	// 					display: 'flex',
	// 					flexDirection: 'column',
	// 					padding: '5px 10px',
	// 					'& .rdrInputRangeInput': {
	// 						marginRight: '0px',
	// 					},
	// 				},
	// 				[theme.breakpoints.down('400')]: {
	// 					gap: '6px',
	// 				},
	// 			},
	// 		},
	// 	},
	// 	'& .rdrCalendarWrapper': {
	// 		borderRadius: '0px 10px 10px 0px',
	// 		'& .rdrDateDisplayWrapper': {
	// 			borderRadius: '0px 10px 0px 0px',
	// 			'& .rdrDateDisplay': {
	// 				[theme.breakpoints.down('460')]: {
	// 					margin: '0px',
	// 					'& .rdrDateInput': {
	// 						marginLeft: '0px',
	// 					},
	// 				},
	// 			},
	// 		},
	// 		'& .rdrMonthAndYearWrapper': {
	// 			[theme.breakpoints.down('460')]: {
	// 				paddingTop: '0px',
	// 				'& .rdrPprevButton': {
	// 					margin: '0px 1px',
	// 				},
	// 				'& .rdrNextButton': {
	// 					margin: '0px 1px',
	// 				},
	// 			},
	// 			[theme.breakpoints.down('451')]: {
	// 				width: 'fit-content',
	// 			},
	// 			'& .rdrMonthAndYearPickers': {
	// 				'& select': {
	// 					[theme.breakpoints.between('326', '360')]: {
	// 						padding: '10px 20px 10px 3px',
	// 					},
	// 					[theme.breakpoints.down('326')]: {
	// 						padding: '10px 20px 10px 0px',
	// 					},
	// 				},
	// 				[theme.breakpoints.down('460')]: {
	// 					'& .rdrMonthPicker': {
	// 						margin: '0px 1px',
	// 					},
	// 					'& .rdrYearPicker': {
	// 						margin: '0px 1px',
	// 					},
	// 				},
	// 			},
	// 		},
	// 		'& .rdrMonths': {
	// 			'& .rdrMonth': {
	// 				[theme.breakpoints.between('451', '520')]: {
	// 					padding: '0px',
	// 					width: '23.667em',
	// 				},
	// 				[theme.breakpoints.between('360', '451')]: {
	// 					padding: '0px',
	// 					width: '19.3em',
	// 				},
	// 				[theme.breakpoints.down('360')]: {
	// 					padding: '0px',
	// 					width: '16.3em',
	// 				},
	// 			},
	// 		},
	// 	},
	// },
	dateButtonText: {
		paddingRight: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		fontSize: theme.typography.pxToRem(11),
		letterSpacing: theme.typography.pxToRem(1),
		// fontFamily: '"Roboto"'
	},
	titleTxt: {
		fontSize: theme.typography.pxToRem(22),
		letterSpacing: theme.typography.pxToRem(2),
		color: theme.palette.almostBlack[700],
		[theme.breakpoints.down('1280')]: {
			fontSize: theme.typography.pxToRem(16),
		},
	},
	filterBtn: {
		position: 'absolute',
		bottom: 16,
		right: 16,
	},
	actionBtn: {
		padding: '3px 10px',
		fontSize: '0.8125rem',
	},
	btnGrp: {
		'& .MuiButtonGroup-groupedContainedPrimary': {
			borderColor: 'transparent',
		},
		'& .MuiButtonBase-root': {
			padding: '4.4px 4px',
		},
	},
	menuList: {
		'& .MuiListItem-root': {
			minWidth: '115px',
		},
	},
	actionContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		'& Button': {
			marginLeft: theme.spacing(1),
		},
		[theme.breakpoints.down('xs')]: {
			justifyContent: 'center',
			'& Button': {
				// maxWidth:400,
				// marginLeft: theme.spacing(0),
				// width: '100%',
			},
		},
	},
	sortBtn: {
		width: '100%',
		textTransform: 'capitalize',
		fontWeight: 'normal',
		color: theme.palette.grey[600],
		padding: '4px 10px',
		'&:hover': {
			backgroundColor: 'transparent',
			color: theme.palette.primary.main,
		},
		'& .MuiIcon-fontSizeSmall': {
			fontSize: '1.25rem',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '4px 5px',
		},
		[theme.breakpoints.only('sm')]: {
			textAlign: 'right',
		},
	},
	sortedValBtn: {
		padding: '4px 1px 4px 10px',
		width: '140px',
		textAlign: 'end',
		'& .MuiButton-label': {
			width: '95%',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			display: 'block',
			textOverflow: 'ellipsis',

			[theme.breakpoints.down('sm')]: {
				lineHeight: 'unset',
			},
			[theme.breakpoints.down(330)]: {
				width: '65%',
			},
		},
	},
	iconBtn: {
		padding: '12px 3px',
	},
}))

var clickedTimes = 0

const DateRangeInput = ({ initialDates, handleDateChange }) => {
	const classes = useStyles()

	const [isCalendarOpen, setIsCalendarOpen] = useState(false)

	const [dates, setDates] = useState(() => [
		{
			startDate: initialDates && initialDates.startDate ? initialDates.startDate : subDays(new Date(), 2000),
			endDate: initialDates && initialDates.endDate ? initialDates.endDate : new Date(),
			key: 'dates',
		},
	])

	useEffect(() => {
		if (!isSameDay(dates[0]?.startDate, initialDates?.startDate) || !isSameDay(dates[0]?.endDate, initialDates?.endDate)) {
			setDates([
				{
					key: 'dates',
					startDate: initialDates?.startDate,
					endDate: initialDates?.endDate,
				},
			])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialDates])

	const handleChange = (dateObj) => {
		clickedTimes = clickedTimes + 1
		const hasStartDateChanged = format(dateObj.dates.startDate, 'dd-MM-yyyy') !== format(dates[0].startDate, 'dd-MM-yyyy')
		const hasEndDateChanged = format(dateObj.dates.endDate, 'dd-MM-yyyy') !== format(dates[0].endDate, 'dd-MM-yyyy')

		if (hasStartDateChanged || hasEndDateChanged) {
			setDates([
				{
					key: 'dates',
					startDate: Object.values(dateObj)[0].startDate,
					endDate: Object.values(dateObj)[0].endDate,
				},
			])
		}

		if (
			dateObj.dates.key !== 'dates' ||
			(hasStartDateChanged && hasEndDateChanged && clickedTimes >= 2) ||
			(!hasStartDateChanged && clickedTimes >= 2) ||
			(!hasEndDateChanged && clickedTimes >= 2)
		) {
			if (dateObj.dates.key !== 'custom range') {
				clickedTimes = 0
				setIsCalendarOpen(false)
			}
		}
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => handleDateChange && handleDateChange(dates[0]), [dates])

	useEffect(() => {
		if (!isCalendarOpen) {
			clickedTimes = 0
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCalendarOpen, clickedTimes])

	function formatDateForLabel(dateString, isReturnAsDate) {
		//   console.log("dateString", dateString)
		let dt = new Date(dateString)
		let today = new Date()
		let todayStr = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
		let yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
		let yesterdayStr = yesterday.getDate() + '/' + (yesterday.getMonth() + 1) + '/' + yesterday.getFullYear()
		let dtStr = dt.getDate() + '/' + (dt.getMonth() + 1) + '/' + dt.getFullYear()
		if (_.isEqual(todayStr, dtStr) && !isReturnAsDate) {
			return 'Today'
		} else if (_.isEqual(yesterdayStr, dtStr) && !isReturnAsDate) {
			return 'Yesterday'
		} else {
			return dtStr
		}
	}

	function dateLabel(dateObj) {
		if (formatDateForLabel(dateObj.startDate) === formatDateForLabel(dateObj.endDate)) {
			return (
				<Typography variant='subtitle2' className={classes.dateButtonText}>
					{formatDateForLabel(dateObj.endDate)}
				</Typography>
			)
		} else {
			return (
				<>
					<Typography variant='subtitle2' className={classes.dateButtonText}>
						{formatDateForLabel(dateObj.startDate, true)}
					</Typography>
					-
					<Typography variant='subtitle2' className={classes.dateButtonText}>
						{formatDateForLabel(dateObj.endDate, true)}
					</Typography>
				</>
			)
		}
	}

	return (
		<>
			{/* <Button onClick={()=>setIsCalendarOpen(!isCalendarOpen)} variant='outlined'>
                                {dateLabel(dates[0])}
                                <Icon>date_range</Icon>
                            </Button> */}
			<ButtonGroup variant='outlined' size='small' color='primary'>
				<Button onClick={() => setIsCalendarOpen(!isCalendarOpen)}>{dateLabel(dates[0])}</Button>
				<Button onClick={() => setIsCalendarOpen(!isCalendarOpen)} size='small'>
					<Icon>{isCalendarOpen ? 'close' : 'date_range'}</Icon>
				</Button>
			</ButtonGroup>
			{isCalendarOpen ? (
				<>
					{/* <ClickAwayListener onClickAway={() => setIsCalendarOpen(false)}>
					<DateRangePicker
						className={classes.calendarWrapper}
						onChange={(item) => {
							handleChange(item)
						}}
						// onRangeFocusChange={item => console.log("onshowndate change",item)}
						showSelectionPreview={true}
						moveRangeOnFirstSelection={false}
						months={1}
						ranges={dates}
						staticRanges={defaultStaticRanges}
						inputRanges={defaultInputRanges}
						maxDate={new Date()}
						direction='horizontal'
					/>
				</ClickAwayListener> */}
					<Dialog
						open={isCalendarOpen}
						maxWidth='md'
						disableRestoreFocus
						className={classes.calendarContainer}
						onClose={() => setIsCalendarOpen(false)}
					>
						<DateRangePicker
							className={classes.calendarWrapper}
							onChange={(item) => {
								handleChange(item)
							}}
							showSelectionPreview={true}
							moveRangeOnFirstSelection={false}
							months={1}
							ranges={dates}
							staticRanges={defaultStaticRanges}
							inputRanges={defaultInputRanges}
							// {...inputProps}
							maxDate={new Date()}
							direction='horizontal'
						/>
					</Dialog>
				</>
			) : null}
		</>
	)
}

const Layout = ({
	isPickerMode,
	handlePicked,
	handleCancel,
	pickButtonLabel,
	cancelButtonLabel,
	setAddPickedList,
	addPickedList,
	exportBtnTxt,
	buttonText,
	buttonIcon,
	page,
	newFilters,
	additionalTopFilter,
	hideButton,
	paginationFooterDetails,
	handleTimeChange,
	bodyScroll,
	fixedLayout,
	initialTime,
	layoutTitle,
	layoutTitleIcon,
	headers,
	mobileheaders,
	mobileHeaderKeys,
	mobileContent,
	data,
	isLoading,
	goToPage,
	limit,
	timeFilter,
	setPageLimit,
	onClickRow,
	onClickDashX,
	onClickGlassX,
	flagStatus,
	isCheckBoxNeeded,
	onCheckBoxClick,
	keyForCheckBoxSelectedList,
	keyForCheckBoxDisabling,
	handleCheckBoxClick,
	handleAllSelectCheckBoxClick,
	handleSelectCurrentPage,
	handleSelectAllPageProducts,
	allPageProduct,
	selectedData,
	exportList,
	exportFxn,
	sortSettings,
	handleSearchInputChange,
	searchQuery,
	noScrapperFilter,
	keyForEditToggle,
	handleCellEdit,
	wholeData,
	extraFilterProps,
	showTheseFilters,
	clearSelection,
	handleSelectAllProducts,
	searchSuggestionsProductType,
	groupDataBy,
	fxnToFormatGroupByKey,
	totalData,
	enableBorderBottom,
	setType,
	pageType,
	setExportList,
	setTableData,
	setAllPageProduct,
	onClickDashboardConfig,
	isViewAllowed,
	isCreateAllowed,
	isEditAllowed,
	isDeleteAllowed,
	isExportAllowed,
	selectionMode,
	setSelectionMode,
	sortable_fields = null,
	sortingDetails,
	setSortingDetails,
	sortFilter,
}) => {
	const classes = useStyles()

	const dispatch = useDispatch()

	const theme = useTheme()
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('1280'))
	const [openFilterDialog, setOpenFtrDialog] = useState(false)

	const options = ['TASK', isExportAllowed && 'EXPORT SELECTED'].filter(Boolean)

	const [open, setOpen] = React.useState(false)
	const anchorRef = React.useRef(null)
	const dashboardConfigAnchorRef = React.useRef(null)

	const [selectedIndex, setSelectedIndex] = React.useState(0)

	const [openExportDialog, setOpenExportDialog] = useState(false)

	const [sortAnchorEl, setSortAnchorEl] = React.useState(null)

	const activityState = sortingDetails?.sort_by
	const activityIcon = sortingDetails?.sort_by_order === 'desc' ? 'arrow_upward' : 'arrow_downward'

	const exportSearchParams = useMemo(
		() => ({
			search_text: searchQuery,
			limit: 12,
			offset: 0,
		}),
		[searchQuery]
	)

	const toggleExportDialog = () => {
		setOpenExportDialog(!openExportDialog)
	}

	const onClickExport = () => {
		if (_.isEmpty(exportList)) {
			if (pageType === 'peoplex') {
				dispatch(showSnackbarWithTimeout('Please select some people to export', 3000))
			} else if (pageType === 'modelx') {
				dispatch(showSnackbarWithTimeout('Please select some agents to export', 3000))
			} else {
				dispatch(showSnackbarWithTimeout('Please select some products to export', 3000))
			}
		} else {
			toggleExportDialog()
		}
	}

	const handleClick = () => {
		if (options[selectedIndex] === 'TASK') {
			exportFxn(exportList)
		} else if (options[selectedIndex] === 'EXPORT SELECTED') {
			onClickExport()
		} else {
			console.info(`You clicked ${options[selectedIndex]}`)
		}
	}

	const handleMenuItemClick = (event, index) => {
		setSelectedIndex(index)
		setType(options[index])
		setOpen(false)
		clearSelection()
	}

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen)
	}

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return
		}
		setOpen(false)
	}

	const closeFilterDialog = () => {
		setOpenFtrDialog(false)
	}

	const onClickFilterBtn = () => {
		setOpenFtrDialog(true)
	}

	const getFiltersSection = (isSmallDevice) => {
		return (
			<Grid lg={2} item container className={classes.filterSection}>
				{
					!_.isNil(newFilters) && newFilters === true ? (
						<NewFilters
							onCloseDialog={closeFilterDialog}
							isSmallDevice={isSmallDevice}
							noScrapperFilter={noScrapperFilter}
							showTheseFilters={showTheseFilters}
							extraFilterProps={extraFilterProps}
							setExportList={setExportList}
							setTableData={setTableData}
							setAllPageProduct={setAllPageProduct}
							searchSuggestionsProductType={searchSuggestionsProductType}
						/>
					) : null
					//  : _.isEqual(pageType, 'peoplex') && (
					// 	<PeoplexNewFilters
					// 		onCloseDialog={closeFilterDialog}
					// 		isSmallDevice={isSmallDevice}
					// 		noScrapperFilter={noScrapperFilter}
					// 		showTheseFilters={showTheseFilters}
					// 		extraFilterProps={extraFilterProps}
					// 		setExportList={setExportList}
					//         setTableData={setTableData}
					// 		setAllPageProduct={setAllPageProduct}
					// 		searchSuggestionsProductType={searchSuggestionsProductType}
					// 	/>
					// )
					// : _.isEqual(pageType, 'modelx') ? (
					// 	<ModelxNewFilters
					// 		onCloseDialog={closeFilterDialog}
					// 		isSmallDevice={isSmallDevice}
					// 		noScrapperFilter={noScrapperFilter}
					// 		showTheseFilters={showTheseFilters}
					// 		extraFilterProps={extraFilterProps}
					// 		setExportList={setExportList}
					// 		setTableData={setTableData}
					// 		setAllPageProduct={setAllPageProduct}
					// 		searchSuggestionsProductType={searchSuggestionsProductType}
					// 	/>
					// ) : (
					// 	<Filters
					// 		isSmallDevice={isSmallDevice}
					// 		noScrapperFilter={noScrapperFilter}
					// 		showTheseFilters={showTheseFilters}
					// 		extraFilterProps={extraFilterProps}
					// 		searchSuggestionsProductType={searchSuggestionsProductType}
					// 	/>
					// )
				}
			</Grid>
		)
	}

	useEffect(() => {
		return () => {
			dispatch(clearAllFilters())
			dispatch(clearSuggestions())
		}
	}, [dispatch])

	const sortable = sortable_fields?.map((item) => {
		const container = {}
		container.label = item?.name
		container.id = item?.id
		container.actionName = 'SORT'

		return container
	})

	sortable?.unshift({ label: 'None', id: null, actionName: 'SORT' })

	const toggleSortBtn = (event) => {
		setSortAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setSortAnchorEl(null)
	}

	const sortBy = (field, type) => {
		let order = _.isEqual(type, 'arrow_downward') ? 'asc' : 'desc'
		if (field === 'None') {
			setSortingDetails({ sort_by: '', sort_by_order: '' })
			setSortAnchorEl(null)
			sortFilter('none', 'asc')
		} else {
			setSortingDetails({ sort_by: field, sort_by_order: order })
			setSortAnchorEl(null)
			sortFilter(field, order)
		}
	}

	return (
		<>
			<Grid
				container
				className={classnames(isPickerMode && _.isEqual(pageType, 'peoplex') ? classes.pickerLayout : classes.layout)}
				justifyContent='center'
			>
				{!isSmallDevice && !_.isEqual(pageType, 'modelx') && !_.isEqual(pageType, 'peoplex') && getFiltersSection(isSmallDevice)}
				{!_.isEqual(pageType, 'modelx') && !_.isEqual(pageType, 'peoplex') && (
					<FullScreenDialog open={openFilterDialog} onClose={closeFilterDialog}>
						{getFiltersSection(isSmallDevice)}
					</FullScreenDialog>
				)}
				<Grid lg={10} item className={classes.contentSection}>
					<Grid item container xs={12} className={classes.tableTitleContainer}>
						<Grid item sm={12} lg={4} alignItems='center' container className={classes.title}>
							<div>
								<Icon className={classes.layoutTitleIcon}>{layoutTitleIcon}</Icon>
								<Typography variant='h5' className={classes.titleTxt} component='span' color='textPrimary'>
									{layoutTitle}
								</Typography>
							</div>
							{isPickerMode && (
								<div>
									<Typography variant='caption' color='textSecondary' gutterBottom>
										* Press and Hold to select People
									</Typography>
								</div>
							)}
						</Grid>
						<Grid item sm={12} lg={8} container justifyContent={'flex-end'} alignItems='center' className={classes.headerFiltersSection}>
							{!_.isNil(additionalTopFilter) && !_.isEmpty(additionalTopFilter) && additionalTopFilter.map((filter, idx) => filter)}
							{handleTimeChange && timeFilter ? (
								<Select
									variant='outlined'
									value={timeFilter}
									onChange={(e) => handleTimeChange(e.target.value)}
									classes={{
										outlined: classes.muiSelectOutlined,
										iconOutlined: classes.muiSelectIconOutlined,
									}}
								>
									{Object.keys(TIME_FILTERS).map((filterOption) => (
										<MenuItem value={filterOption} key={filterOption}>
											{_.get(TIME_FILTERS, `${filterOption}.label`, '')}
										</MenuItem>
									))}
								</Select>
							) : null}
							{_.isEqual(pageType, 'peoplex') ? (
								<Grid item className={classes.actionContainer}>
									<div className={classes.btnContainer} style={{ display: 'flex', flexDirection: 'row' }}>
										<Button
											size='small'
											className={classnames(classes.sortBtn, !_.isEqual(sortingDetails?.sort_by, null) && classes.sortedValBtn)}
											onClick={toggleSortBtn}
											startIcon={_.isEqual(sortingDetails?.sort_by, null) && <Icon fontSize='small'>sort</Icon>}
										>
											{_.isEqual(sortingDetails?.sort_by, null) ? 'Sort by' : _.get(_.find(sortable_fields, { id: sortingDetails.sort_by }), 'name')}
										</Button>
										{!_.isEqual(sortingDetails?.sort_by, null) && (
											<IconButton
												onClick={() => sortBy(activityState, activityIcon === 'arrow_upward' ? 'arrow_downward' : 'arrow_upward')}
												className={classes.iconBtn}
											>
												<Icon
													onClick={() => sortBy(activityState, activityIcon === 'arrow_upward' ? 'arrow_downward' : 'arrow_upward')}
													fontSize='small'
												>
													{activityIcon}
												</Icon>
											</IconButton>
										)}
									</div>
								</Grid>
							) : null}
							<CustomPopoverMenus
								open={Boolean(sortAnchorEl)}
								id={'0'}
								menus={sortable}
								anchorEl={sortAnchorEl}
								onClose={handleCloseMenu}
								sortBy={sortBy}
								activityState={activityState}
								activityIcon={activityIcon}
								className={classes.sortPopover}
							/>

							{initialTime && handleTimeChange && (
								<Grid item container /* xs={2} */ className={classes.calendarContainer} justifyContent='flex-end'>
									<DateRangeInput initialDates={initialTime} handleDateChange={handleTimeChange} />
								</Grid>
							)}

							{/* {isCheckBoxNeeded && isCheckBoxNeeded === true && _.isEqual(hideButton, false) ? (
								<Button
									color='primary'
									variant='contained'
									startIcon={buttonIcon}
									disableElevation
									size='small'
									classes={{ root: classes.muiButtonRoot }}
									onClick={() => exportFxn(exportList)}
								>
									{buttonText}
								</Button>
							) : null} */}
							{isPickerMode ? (
								<>
									<Button variant='outlined' onClick={handleCancel} className={classes.actionBtn}>
										{cancelButtonLabel}
									</Button>
									<Button
										variant='contained'
										color='primary'
										onClick={() => {
											handlePicked()
										}}
										className={classes.actionBtn}
									>
										{pickButtonLabel}
									</Button>
								</>
							) : null}

							{isCheckBoxNeeded && isCheckBoxNeeded === true && _.isEqual(hideButton, false) && !isPickerMode ? (
								<>
									<ButtonGroup variant='contained' color='primary' ref={anchorRef} aria-label='split button' style={{ boxShadow: 'none' }}>
										<Button
											startIcon={_.isEqual(options[selectedIndex], 'TASK') && buttonIcon}
											className={classes.actionBtn}
											onClick={() => handleClick(exportList)}
										>
											{options[selectedIndex]}
										</Button>
										<Button
											color='primary'
											size='small'
											aria-controls={open ? 'split-button-menu' : undefined}
											aria-expanded={open ? 'true' : undefined}
											aria-label='select merge strategy'
											aria-haspopup='menu'
											onClick={handleToggle}
											className={classes.actionBtn}
										>
											<ArrowDropDownIcon />
										</Button>
									</ButtonGroup>

									<Popover
										open={open}
										anchorEl={anchorRef.current}
										onClose={handleClose}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'center',
										}}
									>
										<Paper>
											<ClickAwayListener onClickAway={handleClose}>
												<MenuList id='split-button-menu' className={classes.menuList}>
													{options.map((option, index) => (
														<MenuItem
															key={option}
															disabled={index === 2}
															selected={index === selectedIndex}
															onClick={(event) => handleMenuItemClick(event, index)}
															className={classes.actionBtn}
														>
															{option}
														</MenuItem>
													))}
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Popover>

									<ExportDialog
										open={openExportDialog}
										handleClose={toggleExportDialog}
										searchParams={exportSearchParams}
										// data={selectedResultsIds}
										isSelectedAll={allPageProduct}
										exportList={selectedData}
										pageType={pageType}
									/>
								</>
							) : null}
						</Grid>
					</Grid>
					<ProductTable
						goToPage={goToPage}
						handleSearchInputChange={handleSearchInputChange}
						searchQuery={searchQuery}
						paginationFooterDetails={paginationFooterDetails}
						totalData={totalData}
						limit={limit}
						type={options[selectedIndex]}
						setPageLimit={setPageLimit}
						page={page}
						pageType={pageType}
						headers={headers}
						mobileheaders={mobileheaders}
						mobileHeaderKeys={mobileHeaderKeys}
						mobileContent={mobileContent}
						keyForEditToggle={keyForEditToggle}
						handleCellEdit={handleCellEdit}
						wholeData={wholeData}
						data={data}
						fixedLayout={!_.isNil(fixedLayout) && fixedLayout === true}
						bodyScroll={bodyScroll}
						groupDataBy={groupDataBy}
						fxnToFormatGroupByKey={fxnToFormatGroupByKey}
						onClickRow={onClickRow}
						onClickDashX={onClickDashX}
						onClickGlassX={onClickGlassX}
						setExportList={setExportList}
						selectionMode={selectionMode}
						setSelectionMode={setSelectionMode}
						flagStatus={flagStatus}
						isCheckBoxNeeded={isCheckBoxNeeded}
						handleCheckBoxClick={handleCheckBoxClick}
						handleAllSelectCheckBoxClick={handleAllSelectCheckBoxClick}
						handleSelectCurrentPage={handleSelectCurrentPage}
						handleSelectAllPageProducts={handleSelectAllPageProducts}
						allPageProduct={allPageProduct}
						isPickerMode={isPickerMode}
						handlePicked={handlePicked}
						handleCancel={handleCancel}
						pickButtonLabel={pickButtonLabel}
						cancelButtonLabel={cancelButtonLabel}
						setAddPickedList={setAddPickedList}
						addPickedList={addPickedList}
						exportList={exportList}
						sortSettings={sortSettings}
						clearSelection={clearSelection}
						enableBorderBottom={enableBorderBottom}
						handleSelectAllProducts={handleSelectAllProducts}
						keyForCheckBoxSelectedList={keyForCheckBoxSelectedList}
						keyForCheckBoxDisabling={keyForCheckBoxDisabling}
						onCheckBoxClick={onCheckBoxClick}
						isLoading={isLoading}
					/>

					{isSmallDevice && (
						<Fab size='small' color='primary' aria-label='filters' onClick={onClickFilterBtn} className={classes.filterBtn}>
							<Icon>filter_list</Icon>
						</Fab>
					)}
				</Grid>
			</Grid>
		</>
	)
}

export default Layout
