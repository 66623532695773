import {
	Container,
	Icon,
	makeStyles,
	Typography,
	Fab,
	Grow,
	Grid,
	Badge,
	Button,
	useTheme,
	Link,
	TablePagination,
	IconButton,
} from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Settings from './Settings'
import classnames from 'classnames'
import { useUrlSearchParams } from 'hooks/use-url-search-params'
import Header from './header'
import { useDispatch, useSelector } from 'react-redux'
import {
	fetchGlassDetails,
	getGlassFilter,
	refreshStickyList,
	resetGlass,
	setGlassFilter,
	removeSticky,
	removeMultiSticky,
} from 'services/glass/actions'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import AddMemberDialog from 'components/AddMemberDialog'
import { API_ENDPOINTS, GLASS_LAYOUT, GLASS_ROLES, STICKY_STATUS_ORDER } from 'constants/index'
import KanabanBoard from './Board'
import ActivityDrawer from './ActivityDrawer'
import { io } from 'socket.io-client'
import SocketContext from './socket-context'
import socketEventsWatcher from './socket-events-watcher'
import { hideLoader, showErrorMessage, showLoader, showSuccessMessage, showConfirmMessage } from 'services/loader/actions'
import { usePermission } from 'hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES, PERMISSION_TYPE_STICKY } from 'constants/modules'
import FilterMenuList from './FilterMenu'
import StickySlider from './StickySlider'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import AgGridCustom from 'components/AgGridCustom'
import { convertGmtToLocalTime, convertLocalTimeToGmtStr, getErrMsg, getSuccessMsg } from 'utils'
import GlassxApi from 'services/glass/api'
import Axios from 'axios'
import { updateGlassSticky } from 'services/glass/actions'
import PopoverMenus from 'components/PopoverMenus'
import CloneForm from './MultipleStickyAction/CloneForm'
import MoveForm from './MultipleStickyAction/MoveForm'
import AssigneeForm from './MultipleStickyAction/AssigneeForm'
import ReportingToForm from './MultipleStickyAction/ReportingToForm'
import PaneForm from './MultipleStickyAction/PaneForm'
import moment from 'moment'
import Table from './Table/index'
import {
	withCellRenderState,
	AssignCellRenderer,
	StatusCellRenderer,
	ActionCellRenderer,
	PriorityCellRenderer,
	labelCellRederer,
	dueDateCellRenderer,
	ReportingCellRenderer,
	parentCodeCellRenderer,
	titleCellRenderer,
	RatingCellRenderer,
	StickyGroupCellRenderer,
	PercentageCellRenderer,
} from './customCells'

const MENU_WIDTH = 320

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: 'calc(100vh - 0px)',
		position: 'relative',
		boxSizing: 'border-box',
		'& textarea': {
			font: 'inherit',
		},
		'& a.sc-iemWCZ.ivkEkN': {
			textAlign: 'center',
			textDecoration: 'none',
		},
	},
	errorMsg: {
		textAlign: 'center',
	},
	errContainer: {
		padding: theme.spacing(4),
		textAlign: 'center',
	},
	errIcon: {
		color: theme.palette.error.main,
		fontSize: '2.5em',
	},
	fab: {
		position: 'fixed',
		zIndex: 100,
		bottom: '54px',
		right: '24px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	menuContainer: {
		width: 0,
		opacity: 0,
		height: '100%',
		boxSizing: 'border-box',
		transition: 'width 200ms',
		[theme.breakpoints.down('xs')]: {
			width: `0.00001vw`,
		},
	},
	openMenu: {
		width: MENU_WIDTH,
		opacity: 1,
		zIndex: 1,
		height: '100%',
		boxShadow: `0px 0px 4px 0px ${theme.palette.almostBlack[400]}`,
		background: 'white',
	},

	boardContainer: {
		width: `calc(100% - ${MENU_WIDTH}px)`,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		boxSizing: 'border-box',
		position: 'relative',
	},

	mobileBoardContainerOpened: {
		[theme.breakpoints.down('xs')]: {
			'&::after': {
				position: 'absolute',
				width: '100%',
				height: '100%',
				content: '" "',
				background: 'rgba(0,0,0,0.5)',
				zIndex: 1,
			},
		},
		paddingBottom: theme.spacing(0),
	},
	containerRoot: {
		overflow: 'hidden',
	},
	rootContainer: {
		height: '100%',
	},
	tableRoot: {
		padding: theme.spacing(2),
	},
	exportBtn: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(2),
	},
	allpagesLink: {
		fontSize: 14,
		display: 'block',
		marginTop: theme.spacing(1),
		cursor: 'pointer',
	},
	selectedContent: {
		display: 'block',
		marginTop: theme.spacing(1),
	},
}))

const converTimeToGMT = (value) => {
	return moment(value).isValid() ? convertLocalTimeToGmtStr(value) : value
}

const convertTimeToLocal = (value) => {
	return moment(value).isValid() ? convertGmtToLocalTime(value) : value
}

const GlassX = ({ history, match, location }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const params = useParams()
	const socketIO = useRef()
	const [socketState, setSocketState] = useState({ isSocketConnected: false, sessionId: '' })
	const [filterMenuProps, setMenuProps] = useState({ isOpen: false, page: 'menulist', isButtonVisible: true })
	const [isBoardView, setIsBoardView] = useState(false)
	const token = useSelector((state) => _.get(state, 'session.authToken'))
	const [param, setParam] = useState({ search: '', offset: 0, limit: 10 })
	const [state, setState] = useState({ loading: false, stickyListView: [], totalCount: 0 })
	const cancelExecutor = useRef()
	const { loading, stickyListView, totalCount } = state
	const { isSocketConnected, sessionId } = socketState
	// const [openFilter, setOpenFilter] = useState(false)
	const glassDetails = useSelector((state) => _.get(state, 'glass', {}))
	const currentUserId = useSelector((state) => _.get(state, 'session.user.userDetails.user_id'))
	const authToken = useSelector((state) => _.get(state, 'session.authToken'))
	const reduxFilters = useSelector((state) => _.get(state, 'glass.selectedFilters', {}))
	const [filter, setFilter] = useState({ searchText: '', filterData: {} })
	const { filterData } = filter
	const isFilterActive = useMemo(() => !_.isEmpty(_.omitBy(filterData, _.isEmpty)), [filterData])
	const [selected, setSelected] = useState('')
	const [selectedAll, setSelectedAll] = useState(false)
	const [search, setSearch] = useState('')

	const { isFetching, isFailed, errorMessage, glass_code, glass_name, project_name, glass_members, glass_desc, dashboard } = glassDetails

	const [pageQuery, setPageQuery] = useUrlSearchParams(
		{ settings: false, addMembers: false, activityDrawer: false },
		{ settings: Boolean, addMembers: Boolean, activityDrawer: Boolean }
	)
	const { settings, addMembers, activityDrawer } = pageQuery

	//check current user is owner of the glass
	const isOwner = useMemo(() => _.findIndex(glass_members, { user_id: currentUserId, role: GLASS_ROLES.OWNER }) > -1, [glass_members, currentUserId])
	const isGuest = useMemo(() => _.findIndex(glass_members, { user_id: currentUserId, role: GLASS_ROLES.GUEST }) > -1, [glass_members, currentUserId])
	const isMember = useMemo(
		() => _.findIndex(glass_members, { user_id: currentUserId, role: GLASS_ROLES.MEMBER }) > -1,
		[glass_members, currentUserId]
	)

	const glassMembers = useMemo(
		() => _.filter(glass_members, (member) => !_.isEqual(member?.role, GLASS_ROLES.GUEST) && member?.is_active),
		[glass_members]
	)
	const glassPerms = usePermission(ROLE_MODULES.GLASS)
	const isCreateAllowed = useMemo(() => _.get(glassPerms, PERMISSION_TYPE.CREATE, false), [glassPerms])
	const isEditAllowed = useMemo(() => _.get(glassPerms, PERMISSION_TYPE.EDIT, false), [glassPerms])
	const isDeleteAllowed = useMemo(() => _.get(glassPerms, PERMISSION_TYPE.DELETE, false), [glassPerms])
	const isExportAllowed = useMemo(() => _.get(glassPerms, PERMISSION_TYPE.EXPORT, false), [glassPerms])

	const glassStickyGroups = useSelector((state) => _.get(state, 'glass.settings.sticky_group'))
	const glassLabels = useSelector((state) => _.get(state, 'glass.settings.labels'))
	const activityTypes = useSelector((state) => _.get(state, 'glass.settings.activity_type'))
	const glassLayout = useSelector((state) => _.get(state, 'glass.settings.layout', GLASS_LAYOUT.NORMAL))
	const isListView = useMemo(() => _.isEqual(glassLayout, GLASS_LAYOUT.LIST), [glassLayout])

	console.log('print')

	useEffect(() => {
		if (!_.isEmpty(reduxFilters)) {
			setFilter((prevState) => ({
				...prevState,
				filterData: {
					...reduxFilters,
					due_date: reduxFilters?.due_date && {
						from: convertTimeToLocal(reduxFilters?.due_date?.from),
						to: convertTimeToLocal(reduxFilters?.due_date?.to),
					},
					created_date: reduxFilters?.created_date && {
						from: convertTimeToLocal(reduxFilters?.created_date?.from),
						to: convertTimeToLocal(reduxFilters?.created_date?.to),
					},
				},
			}))
		}
	}, [reduxFilters])

	const lanesData = useMemo(() => {
		const stickyList = _.get(glassDetails, 'stickyList', [])
		return _.sortBy(
			_.map(_.get(glassDetails, 'settings.panes', []), (pane) => ({
				...pane,
				id: pane?.pane_id,
				cards: _.sortBy(
					_.map(_.get(_.find(stickyList, { pane_id: pane?.pane_id }), 'sticky', []), (sticky, pos) => ({
						...sticky,
						position: pos,
						id: sticky?.sticky_code,
					})),
					['sticky_pos']
				),
			})),
			['pane_position']
		)
	}, [glassDetails])

	const glassCode = _.get(params, 'glassCode', '')

	const getTableData = useCallback((glassDetails) => {
		// const paneList = _.get(glassDetails, 'settings.panes', [])
		// const stickyList = _.filter(_.get(glassDetails, 'stickyList', []), (pane) => {
		// 	return !_.isEmpty(_.find(paneList, { pane_id: pane.pane_id }))
		// })
		let stickyArr = []
		// _.map(stickyList, (pane) => {
		_.map(glassDetails, (sticky) => {
			stickyArr.push({
				...sticky,
				assign_first_name: sticky?.assigned_to?.first_name,
				reporting_first_name: sticky?.reporting_to?.first_name,
				paneName: sticky?.pane_name,
				status: _.indexOf(STICKY_STATUS_ORDER, sticky?.sticky_status?.status),
				parentCode: sticky?.parent_sticky_code,
			})
		})
		// })
		return stickyArr
	}, [])

	const getStickyListView = (search, offset, limit) => {
		setState({ loading: true, stickyListView: [], totalCount: 0 })
		GlassxApi.fetchStickyListView(glassCode, param?.search, offset, limit, cancelExecutor)
			.then((res) => {
				setState({ loading: false, stickyListView: _.get(res, 'data.data.data', []), totalCount: _.get(res, 'data.data.total_count', 0) })
			})
			.catch((err) => {
				if (!Axios.isCancel(err)) setState({ loading: false, stickyListView: [], totalCount: 0 })
				// console.log(err, 'err')
			})
	}

	const tableData = isBoardView ? [] : getTableData(stickyListView)

	const data = { lanes: lanesData }

	const stickiesCount = _.sum(
		_.map(
			_.map(data?.lanes, (data) => {
				return data
			}),
			(data) => {
				return data?.cards.length
			}
		)
	)

	const [selectAll, setSelectAll] = useState(false)
	const [rowsselected, setRowsSelected] = useState([])
	const [rowsselectedData, setRowsSelectedData] = useState([])
	const handleSelectAllClick = (event, rowData) => {
		const isAllSelected = _.indexOf(rowsselected, 'all') !== -1
		const exportListLength = rowsselected.length
		if (isAllSelected) {
			setRowsSelected([])
			setRowsSelectedData([])
			setSelectedAll(false)
		} else {
			if (exportListLength > 0) {
				setRowsSelected([])
				setRowsSelectedData([])
				setSelectedAll(false)
			} else {
				setRowsSelected(_.compact(tableData.map((n) => n?.sticky_code)))
				setRowsSelectedData(_.compact(tableData).map((value) => value))
				setSelectedAll(false)
			}
		}
	}

	const handleCheckboxClick = (event, record) => {
		event.stopPropagation()

		const selectedIndex = rowsselected.indexOf(record.sticky_code)
		let newSelected = []
		let newSelectedData = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(rowsselected, record.sticky_code)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(rowsselected.slice(1))
		} else if (selectedIndex === rowsselected.length - 1) {
			newSelected = newSelected.concat(rowsselected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(rowsselected.slice(0, selectedIndex), rowsselected.slice(selectedIndex + 1))
		}

		if (selectedIndex === -1) {
			newSelectedData = newSelectedData.concat(rowsselectedData, record)
		} else if (selectedIndex === 0) {
			newSelectedData = newSelectedData.concat(rowsselectedData.slice(1))
		} else if (selectedIndex === rowsselectedData.length - 1) {
			newSelectedData = newSelectedData.concat(rowsselectedData.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelectedData = newSelectedData.concat(rowsselectedData.slice(0, selectedIndex), rowsselectedData.slice(selectedIndex + 1))
		}

		setRowsSelected(newSelected)
		setRowsSelectedData(newSelectedData)
		setSelectedAll(false)
	}

	const isSelected = (record) => rowsselected.indexOf(record.sticky_code) !== -1

	const onChangePage = (e, pageNo) => {
		setParam({ ...param, offset: pageNo * param.limit })
		setSelectedRows([])
		setSelectedAll(false)
	}

	const changeRowPerPage = (e) => {
		setParam({ ...param, limit: e.target.value, offset: 0 })
	}

	// console.log(state, 'state')

	// const theme = useTheme()
	// const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

	// const [stickyData, setStickyData] = useState({ stickyDetails: {}, loading: true })
	// console.log('stickyData', stickyData)

	// const cardId = _.get(match, 'params.cardId')

	// const stickyUpdatesListener = useRef()
	// const stickyRoomJoinListener = useRef()
	// const stickyDeleteListener = useRef()
	// const autoSaveCancelExec = useRef()

	// const updateStickyDetails = useCallback(
	// 	(stickyDetails, skipReduxUpdate = false) => {
	// 		console.log('socket', stickyDetails)
	// 		//skip redux update flag is true then don't update on redux
	// 		if (!skipReduxUpdate) dispatch(updateGlassSticky(stickyDetails))
	// 		setStickyData((prevState) => ({ ...prevState, stickyDetails: stickyDetails }))
	// 	},
	// 	[dispatch]
	// )

	// useEffect(() => {
	// 	if (isSocketConnected) {
	// 		stickyUpdatesListener.current = (resp) => {
	// 			if (_.isEqual(cardId, _.get(resp, 'data.sticky_code'))) {
	// 				//skip redux update since it's from socket already we have listener on socket-events-watcher.js
	// 				updateStickyDetails(resp?.data, true)
	// 			}
	// 		}
	// 		stickyDeleteListener.current = (resp) => {
	// 			if (_.isEqual(cardId, _.get(resp, 'data.sticky_code'))) {
	// 				history.push(`/glassx/view/${glassCode}`)
	// 				dispatch(showSnackbarWithTimeout('Sticky got deleted!', 3000))
	// 			}
	// 		}
	// 		socketIO.current?.on('sticky_update', stickyUpdatesListener.current)
	// 		socketIO.current?.on('sticky_delete', stickyDeleteListener.current)
	// 	}
	// 	return () => {
	// 		if (isSocketConnected && stickyUpdatesListener?.current && stickyDeleteListener.current) {
	// 			socketIO.current?.off('sticky_update', stickyUpdatesListener.current)
	// 			// eslint-disable-next-line react-hooks/exhaustive-deps
	// 			socketIO.current?.off('sticky_delete', stickyDeleteListener.current)
	// 		}
	// 	}
	// }, [socketIO, cardId, updateStickyDetails, isSocketConnected, glassCode, history, dispatch])

	useEffect(() => {
		if (isListView) {
			getStickyListView(param.search, param.offset, param.limit)
		}
	}, [param, isListView, glassDetails])

	useEffect(() => {
		dispatch(fetchGlassDetails(glassCode))
	}, [dispatch, glassCode])

	useEffect(() => {
		if (glassCode) {
			dispatch(getGlassFilter(glassCode, sessionId))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [glassCode])

	const onSearchChange = (searchText) => {
		setFilter((prevState) => ({ ...prevState, searchText }))
		{
			!isListView && dispatch(refreshStickyList(glassCode, searchText))
		}
		setParam({ ...param, search: searchText, offset: 0 })
		setSearch(searchText)
		setSelectedRows([])
		setSelectedAll(false)
	}
	const updateFilter = useCallback(
		(value, param) => {
			let formatedData = {
				...value,
				due_date: !_.isEmpty(value?.due_date) && {
					from: converTimeToGMT(value?.due_date?.from),
					to: converTimeToGMT(value?.due_date?.to),
				},
				created_date: !_.isEmpty(value?.created_date) && {
					from: converTimeToGMT(value?.created_date?.from),
					to: converTimeToGMT(value?.created_date?.to),
				},
			}
			setFilter((prevState) => ({ ...prevState, filterData: formatedData }))
			let data = _.omitBy(formatedData, _.isEmpty)
			setSelectedRows([])
			setSelectedAll(false)
			// setFilterActive(!_.isEmpty(data))
			if (glassCode) {
				setFilter((prevState) => {
					console.log('setGlassFilter', search, prevState?.searchText)
					dispatch(
						setGlassFilter(
							glassCode,
							sessionId,
							data,
							prevState?.searchText,
							// search,
							(res) => {
								setParam({ ...param, limit: 10, offset: 0 })
								// if (isListView) {
								// getStickyListView(param.search, param.offset, param.limit)
								// }
								//  else {
								// 	dispatch(refreshStickyList(glassCode, search))
								// }
							},
							(err) => {
								dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
							}
						)
					)
					return { ...prevState, filterData: value }
				})
			}
		},
		[dispatch, glassCode, sessionId, isListView]
	)
	const onClickDashboard = () => {
		if (dashboard?.dashboard_id) {
			history.push(`/dashx/view/${dashboard?.dashboard_id}?stack=1`)
		}
	}

	const openSettings = () => {
		setPageQuery({ ...pageQuery, settings: true })
	}

	const handleCloseSettings = () => {
		setPageQuery({ ...pageQuery, settings: false })
	}

	const openAddMemDialog = () => {
		setPageQuery({ addMembers: true }, true)
	}

	const handleCloseMembers = () => {
		setPageQuery({ addMembers: false }, true)
	}
	const openActivity = () => {
		setPageQuery({ ...pageQuery, activityDrawer: true })
	}

	const handleCloseActivity = () => {
		setPageQuery({ ...pageQuery, activityDrawer: false })
	}

	const handleCloseSlider = () => {
		history.push(`/glassx/view/${glassCode}/?settings=false&addMembers=false&activityDrawer=false`)
	}

	//CHECK CURRENT USER IS ACCESSIBLE FOR THIS GLASS
	useEffect(() => {
		if (!isFetching && !isFailed && _.findIndex(glass_members, { user_id: currentUserId }) === -1) {
			dispatch(
				showErrorMessage('You can no longer access to this Board', 'Close', () => {
					dispatch(hideLoader())
					history.push(`/glassx/view/${glassCode}/?settings=false&addMembers=false&activityDrawer=false`)
				})
			)
		}
	}, [dispatch, isFetching, isFailed, currentUserId, history, glass_members, glassCode])

	//OPEN SOCKET CONNECTION
	useEffect(() => {
		socketIO.current = io(API_ENDPOINTS.GLASS_SOCKET, {
			transports: ['websocket'],
			query: `access_token=${authToken}`,
		})

		//On Authentication of the user authToken
		socketIO.current.on('onAuthentication', (resp) => {
			if (resp?.status === 'success') {
				setSocketState({ sessionId: socketIO.current.id, isSocketConnected: true })
			}
		})

		socketEventsWatcher(socketIO, dispatch, history)

		socketIO.current.on('disconnect', () => {
			setSocketState({ sessionId: '', isSocketConnected: false })
		})

		return () => {
			socketIO.current.disconnect()
		}
	}, [dispatch, authToken, history])

	//JOIN OR LEAVE CURRENT ACTIVE GLASS ROOM ON SOCKET CONNECTION
	useEffect(() => {
		if (isSocketConnected) {
			socketIO.current.emit('join_glass', { id: glassCode, access_token: authToken })
		}
		return () => {
			if (isSocketConnected) {
				socketIO.current.emit('left_room', { id: glassCode })
			}
		}
	}, [glassCode, authToken, isSocketConnected, dispatch])

	// reseting glass while leaving screen
	useEffect(() => {
		return () => {
			setMenuProps((prev) => ({
				...prev,
				isOpen: false,
			}))
			dispatch(resetGlass())
		}
	}, [dispatch])

	const ToggleView = () => {
		setIsBoardView(!isBoardView)
	}

	const onRowClicked = (row) => {
		const stickyCode = row?.data?.sticky_code
		stickyCode && history.push(`/glassx/view/${glassCode}/${stickyCode}`)
	}

	const gridHeaders = [
		{
			headerName: '',
			field: '',
			maxWidth: 80,
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			checkboxSelection: true,
			// hide: isMobileView,
		},
		{
			headerName: 'Task Code',
			field: 'sticky_code',
			sortable: true,
			minWidth: 150,
		},
		{
			headerName: 'Pane Name',
			field: 'paneName',
			sortable: true,
			minWidth: 150,
			// hide: isMobileView,
		},
		{
			headerName: 'Parent Code',
			field: 'parentCode',
			cellRenderer: 'parentCodeCellRenderer',
			sortable: true,
			maxWidth: 150,
			minWidth: 150,
		},
		{
			headerName: 'Title',
			field: 'sticky_title',
			cellRenderer: 'titleCellRenderer',
			sortable: true,
			minWidth: 150,
		},
		{
			headerName: 'Assignee',
			field: 'assign_first_name',
			cellRenderer: 'assignCellRenderer',
			sortable: true,
			maxWidth: 130,
			minWidth: 130,
			// hide: isMobileView,
			cellRendererParams: {
				token,
			},
		},
		{
			headerName: 'Status',
			field: 'status',
			cellRenderer: 'statusCellRenderer',
			sortable: true,
			minWidth: 130,
			// hide: isMobileView,
		},
		{
			headerName: 'Task Completion %',
			field: 'status',
			cellRenderer: 'percentageCellRenderer',
			sortable: true,
			minWidth: 260,
			// hide: isMobileView,
		},
		{
			headerName: 'Rating',
			field: 'status',
			cellRenderer: 'ratingCellRenderer',
			sortable: true,
			minWidth: 130,
			// hide: isMobileView,
		},
		{
			headerName: 'Due Date & Time',
			field: 'due_date',
			cellRenderer: 'dueDateCellRenderer',
			maxWidth: 180,
			minWidth: 180,

			sortable: true,
			// hide: isMobileView,
		},
		{
			headerName: 'Reporting to',
			field: 'reporting_first_name',
			cellRenderer: 'reportingCellRenderer',
			sortable: true,
			// hide: isMobileView,
			cellRendererParams: {
				token,
			},
			maxWidth: 180,
			minWidth: 160,
		},
		{
			headerName: 'Task Group',
			field: 'status_group',
			cellRenderer: 'stickyGroupCellRenderer',
			sortable: true,
			minWidth: 130,
			// hide: isMobileView,
		},
		{
			headerName: 'Priority',
			field: 'priority',
			cellRenderer: 'priorityCellRenderer',
			maxWidth: 180,
			minWidth: 130,
			sortable: true,
			// hide: isMobileView,
		},
		{
			headerName: 'Label',
			field: 'labels',
			cellRenderer: 'labelCellRederer',
			maxWidth: 180,
			minWidth: 150,
			sortable: true,
			// hide: isMobileView,
		},
	]

	const headers = [
		{
			name: 'Task code',
			dataKey: 'sticky_code',
			align: 'left',
			style: {
				width: '200px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
		},
		{
			name: 'Pane Name',
			dataKey: 'paneName',
			align: 'left',
			style: {
				width: '200px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
		},
		{
			name: 'Parent Code',
			dataKey: 'parentCode',
			align: 'center',
			style: {
				width: '150px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
			isParentCode: true,
		},
		{
			name: 'Title',
			dataKey: 'sticky_title',
			align: 'left',
			style: {
				width: '200px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
			isStickyTitle: true,
		},
		{
			name: 'Assignee',
			dataKey: 'assign_first_name',
			align: 'center',
			style: {
				width: '130px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
			isAssignee: true,
		},
		{
			name: 'Status',
			dataKey: 'status',
			align: 'left',
			style: {
				width: '200px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
			isStatus: true,
		},
		{
			name: 'Task Completion %',
			dataKey: 'task_completion',
			align: 'left',
			style: {
				width: '260px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
			isTaskCompletion: true,
		},
		{
			name: 'Rating',
			dataKey: 'ratings',
			align: 'left',
			style: {
				width: '200px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
			isRating: true,
		},
		{
			name: 'Due Date & Time',
			dataKey: 'due_date',
			align: 'left',
			style: {
				width: '180px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
			isDueDate: true,
		},
		{
			name: 'Reporting to',
			dataKey: 'reporting_first_name',
			align: 'center',
			style: {
				width: '180px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
			isReportingTo: true,
		},
		{
			name: 'Task Group',
			dataKey: 'sticky_group.sticky_group',
			align: 'left',
			style: {
				width: '200px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
		},
		{
			name: 'Priority',
			dataKey: 'priority',
			align: 'center',
			style: {
				width: '180px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
			isPriority: true,
		},
		{
			name: 'Label',
			dataKey: 'labels',
			align: 'left',
			style: {
				width: '180px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
			isLabel: true,
		},
	].filter(Boolean)

	const [selectedRows, setSelectedRows] = useState([])
	const [selectedRowsData, setSelectedRowsData] = useState([])
	const onChangeSelection = (selectedRows) => {
		setSelectedRows(selectedRows)
	}

	const onGridReady = (params) => {
		setSelected(params.api)
	}

	const onAllDataClick = () => {
		// let selectedNodes = selected.getSelectedNodes()
		let selectedDataDetails = tableData.map((node) => node)
		let selectedData = tableData.map((node) => node.sticky_code)
		setSelectedRowsData(selectedDataDetails)
		setSelectedRows(selectedData)
		setSelectedAll(true)
		setRowsSelected(selectedData)
		setRowsSelectedData(selectedDataDetails)
	}

	const onRowSelected = (event, record) => {
		let selectedNodes = selected.getSelectedNodes()
		let selectedDataDetails = selectedNodes.map((node) => node.data)
		let selectedData = selectedNodes.map((node) => node.data.sticky_code)
		setSelectedRowsData(selectedDataDetails)
		setSelectedRows(selectedData)
		setSelectedAll(false)
	}

	const onClickExport = () => {
		let isSelectAll = selectedAll
		dispatch(
			showLoader('The Exporting process has been started and the Exported details of the Board will be downloaded once it is exported completely')
		)
		const exportRecordsParams = {
			is_select_all: isSelectAll,
			search_text: search,
			...(isSelectAll ? {} : { sticky_code: rowsselected }),
		}
		const onSuccess = (response) => {
			try {
				const filename = response.headers['content-disposition'].split('filename=')[1]
				const contentType = response.headers['content-type']
				// response.data.then((blob) => {
				let blob = new Blob([response.data], { type: contentType })
				let url = window.URL.createObjectURL(blob)
				let a = document.createElement('a')
				a.href = url
				a.download = filename
				a.click()
				dispatch(hideLoader())
				selected.deselectAll()
				// })
			} catch (e) {
				// console.log('error', e)
				dispatch(hideLoader())
			}
		}

		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		GlassxApi.export(exportRecordsParams, glassCode).then(onSuccess, onFailure)
	}

	const [anchorEl, setAnchorEl] = useState(null)
	const [openClone, setClone] = useState(false)
	const [openMove, setOpenMove] = useState(false)
	const [openAssignee, setOpenAssignee] = useState(false)
	const [openReportingTo, setOpenReportingTo] = useState(false)
	const [openPane, setOpenPane] = useState(false)

	const actionMenus = [
		isOwner
			? {
					label: 'Delete',
					icon: 'delete',
					actionName: PERMISSION_TYPE.DELETE,
			  }
			: null,
		isOwner
			? {
					label: 'Clone',
					icon: 'content_copy',
					actionName: PERMISSION_TYPE.CLONE,
			  }
			: null,
		isOwner
			? {
					label: 'Move',
					icon: 'drive_file_move',
					actionName: PERMISSION_TYPE.MOVE,
			  }
			: null,
		isOwner
			? {
					label: 'Assignee',
					icon: 'assignment_ind',
					actionName: PERMISSION_TYPE_STICKY.ASSIGNEE,
			  }
			: null,
		isOwner
			? {
					label: 'Reporting To',
					icon: 'how_to_reg',
					actionName: PERMISSION_TYPE_STICKY.REPORTING_TO,
			  }
			: null,
		isOwner
			? {
					label: 'Pane',
					icon: 'swap_horiz',
					actionName: PERMISSION_TYPE_STICKY.PANE,
			  }
			: null,
	]

	const fomatedActionItems = _.compact(actionMenus)

	const handleClickMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const deleteSticky = () => {
		let data = {
			sticky_code: rowsselected,
			is_select_all: selectedAll,
		}
		const onSuccess = (resp) => {
			dispatch(hideLoader())
			// onClose()
			dispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 2500))
			dispatch(refreshStickyList(glassCode, ''))
		}
		const onError = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		askDeleteConfirm(
			() => {
				dispatch(showLoader('Deleting task...'))
				dispatch(removeMultiSticky(sessionId, glassCode, data, onSuccess, onError))
				setSelectedRows([])
				setRowsSelected([])
				setRowsSelectedData([])
			},
			() => {
				dispatch(hideLoader())
			}
		)
	}
	const askDeleteConfirm = (onConfirm, onCancel) => {
		let askMesg =
			'All the actions will  be removed from the activity feed, and you won’t be able to re-open the task. \n Note: Please remove the Subtasks from the task that you need'
		dispatch(showConfirmMessage(askMesg, '', 'Confirm', onConfirm, 'Cancel', onCancel))
	}

	const performAction = (actionName) => {
		switch (actionName) {
			case PERMISSION_TYPE.DELETE:
				deleteSticky()
				break
			case PERMISSION_TYPE.CLONE:
				OpenCloneDialog()
				break
			case PERMISSION_TYPE.MOVE:
				OpenMoveDialog()
				break
			case PERMISSION_TYPE_STICKY.ASSIGNEE:
				OpenAssigneeDialog()
				break
			case PERMISSION_TYPE_STICKY.REPORTING_TO:
				OpenReportingToDialog()
				break
			case PERMISSION_TYPE_STICKY.PANE:
				OpenPaneDialog()
				break
			default:
				break
		}
		handleCloseMenu()
	}

	const OpenCloneDialog = () => {
		setClone(true)
	}

	const OpenMoveDialog = () => {
		setOpenMove(true)
	}

	const OpenAssigneeDialog = () => {
		setOpenAssignee(true)
	}

	const OpenReportingToDialog = () => {
		setOpenReportingTo(true)
	}

	const OpenPaneDialog = () => {
		setOpenPane(true)
	}

	return (
		<div className={classnames('page-content', classes.root)}>
			<SocketContext.Provider value={socketState}>
				{!isFetching && isFailed ? (
					<Container className={classes.errContainer} maxWidth={'md'}>
						<Icon className={classes.errIcon}>error</Icon>
						<Typography variant='body2' className={classes.errorMsg}>
							{errorMessage}
						</Typography>
					</Container>
				) : (
					<Grid container className={classnames(classes.containerRoot, settings && classes.rootContainer)}>
						<div
							className={classnames(classes.boardContainer, {
								[classes.mobileBoardContainerOpened]: filterMenuProps.isOpen,
							})}
						>
							<Header
								glassLayout={glassLayout}
								isLoading={isFetching}
								title={glass_name}
								glassCode={glassCode}
								data={tableData}
								filterMenuProps={filterMenuProps}
								glassDesc={glass_desc}
								dashboard={dashboard}
								sessionId={sessionId}
								subtitle={project_name}
								openSettings={openSettings}
								openActivity={openActivity}
								onSearchChange={onSearchChange}
								onClickDashboard={onClickDashboard}
								isBoardView={isBoardView}
								toggleView={ToggleView}
								searchText={search}
								totalstickies={totalCount}
								gridTotalStickies={stickiesCount}
								isListView={isListView}
							/>
							{!isFetching && settings && (
								<Settings
									isOwner={isOwner}
									selectedData={glassDetails}
									glassName={glass_name}
									glassCode={glass_code}
									dashboard={dashboard}
									projectName={project_name}
									sessionId={sessionId}
									onClose={handleCloseSettings}
									openAddMemDialog={openAddMemDialog}
									history={history}
								/>
							)}
							{!isFetching && (
								<AddMemberDialog
									open={addMembers}
									sessionId={socketState?.sessionId}
									glassCode={glassCode}
									isUpdateRedux={true}
									handleClose={handleCloseMembers}
								/>
							)}
							{!isListView ? (
								<KanabanBoard
									isFetching={isFetching}
									data={data}
									glassCode={glassCode}
									glassMembers={glassMembers}
									isOwner={isOwner}
									isGuest={isGuest}
									sessionId={sessionId}
									isCreateAllowed={isCreateAllowed}
									isEditAllowed={isEditAllowed}
									isDeleteAllowed={isDeleteAllowed}
									history={history}
								/>
							) : (
								<div className={classes.tableRoot}>
									<div className={classes.exportBtn}>
										{/* <div style={{ marginRight: '8px' }}>
											{!_.isEmpty(selectedRows) && _.isEqual(_.size(tableData), _.size(selectedRows)) && (
												<>
													{selectedAll ? (
														<Typography color='primary' variant='caption' className={classes.selectedContent}>
															{totalCount} data Selected!
														</Typography>
													) : (
														<Link onClick={onAllDataClick} className={classes.allpagesLink}>
															Select results of all pages
														</Link>
													)}
												</>
											)}
										</div> */}
										{/* <div style={{ marginRight: '8px' }}>
											<Button
												size='small'
												disabled={_.isEmpty(selectedRows)}
												onClick={onClickExport}
												variant='contained'
												color='primary'
												disableElevation
											>
												Archive
											</Button>
										</div> */}
										<div>
											{!_.isEmpty(rowsselected) && (
												<>
													{selectedAll ? (
														<Typography color='primary' variant='caption' className={classes.selectedContent}>
															{totalCount} data Selected!
														</Typography>
													) : (
														<Link onClick={onAllDataClick} className={classes.allpagesLink}>
															Select results of all pages
														</Link>
													)}
												</>
											)}
										</div>
										<div>
											{isOwner && (
												<IconButton
													size='small'
													edge='end'
													onClick={handleClickMenu}
													style={{ marginRight: '10px' }}
													disabled={_.isEmpty(rowsselected) || selectedAll}
												>
													<Icon>more_vert</Icon>
												</IconButton>
											)}
											<Button
												size='small'
												disabled={_.isEmpty(rowsselected) || !isExportAllowed}
												onClick={onClickExport}
												variant='contained'
												color='primary'
												disableElevation
											>
												Export Selected
											</Button>
										</div>
									</div>
									<PopoverMenus
										open={Boolean(anchorEl)}
										id={'1'}
										menus={fomatedActionItems}
										anchorEl={anchorEl}
										onClickAction={performAction}
										onClose={handleCloseMenu}
									/>
									<CloneForm
										open={openClone}
										setForm={setClone}
										glassMembers={glassMembers}
										lanesData={lanesData}
										glassCode={glassCode}
										stickyCode={rowsselected}
										history={history}
										sessionId={sessionId}
										setSelectedRows={setRowsSelected}
									/>
									<MoveForm
										open={openMove}
										setForm={setOpenMove}
										glassMembers={glassMembers}
										lanesData={lanesData}
										glassCode={glassCode}
										stickyCode={rowsselected}
										history={history}
										sessionId={sessionId}
										setSelectedRows={setRowsSelected}
									/>
									<AssigneeForm
										open={openAssignee}
										setForm={setOpenAssignee}
										glassMembers={glassMembers}
										lanesData={lanesData}
										glassCode={glassCode}
										stickyCode={rowsselected}
										history={history}
										sessionId={sessionId}
										setSelectedRows={setRowsSelected}
									/>
									<ReportingToForm
										open={openReportingTo}
										setForm={setOpenReportingTo}
										glassMembers={glassMembers}
										lanesData={lanesData}
										glassCode={glassCode}
										stickyCode={rowsselected}
										history={history}
										sessionId={sessionId}
										setSelectedRows={setRowsSelected}
									/>
									<PaneForm
										open={openPane}
										setForm={setOpenPane}
										glassMembers={glassMembers}
										lanesData={lanesData}
										glassCode={glassCode}
										stickyCode={rowsselected}
										history={history}
										sessionId={sessionId}
										setSelectedRows={setRowsSelected}
									/>
									<Table
										idKey='sticky_code'
										history={history}
										showLoading={loading}
										// baseUrl='/dataconnection'
										// title='Data Connection'
										// titleIcon='settings_input_hdmi'
										data={tableData}
										headers={headers}
										// hideAddBtn={!isCreateAllowed}
										// tableTitleForMobile='Data Connections'
										// mobileHeaderKeys={gridHeaders}
										// onChangeSearch={onSearchTextBox}
										// onRenderCellVal={onRenderCellVal}
										// getTableRowTag={(record) => (_.get(record, 'permission') && 'Shared') || ''}
										// isActionsDisabled={(record) => {
										// 	return _.isEqual(_.get(record, 'permission'), PERMISSION_TYPE.VIEW)
										// }}
										// emptyImage={<NotFoundImage style={{ fontSize: '5em' }}></NotFoundImage>}
										// actions={actions}
										// onDeleteActionClick={onDeleteMultipleActionClick}
										// onShareActionClick={onShareActionClick}
										// onShareMultipleActionClick={onShareMultipleActionClick}
										handleCheckboxClick={handleCheckboxClick}
										isSelected={isSelected}
										handleSelectAllClick={handleSelectAllClick}
										numSelected={rowsselected?.length}
										rowCount={totalCount}
										selected={rowsselected}
										selectedData={rowsselectedData}
										totalCount={totalCount}
										page={param.offset / param.limit}
										rowsPerPage={param.limit}
									></Table>
									{!loading && (
										<TablePagination
											component='div'
											count={totalCount}
											page={param.offset / param.limit}
											onPageChange={onChangePage}
											rowsPerPage={param.limit}
											onRowsPerPageChange={changeRowPerPage}
										/>
									)}
									{/* <AgGridCustom
										suppressRowClickSelection={true}
										onGridReady={onGridReady}
										rowSelection={'multiple'}
										onChangeSelection={onChangeSelection}
										columnDefs={gridHeaders}
										rowData={tableData}
										onRowSelected={onRowSelected}
										loading={loading}
										frameworkComponents={{
											assignCellRenderer: withCellRenderState(AssignCellRenderer),
											statusCellRenderer: withCellRenderState(StatusCellRenderer),
											priorityCellRenderer: withCellRenderState(PriorityCellRenderer),
											labelCellRederer: withCellRenderState(labelCellRederer),
											dueDateCellRenderer: withCellRenderState(dueDateCellRenderer),
											actionCellRenderer: withCellRenderState(ActionCellRenderer),
											reportingCellRenderer: withCellRenderState(ReportingCellRenderer),
											parentCodeCellRenderer: withCellRenderState(parentCodeCellRenderer),
											titleCellRenderer: withCellRenderState(titleCellRenderer),
											ratingCellRenderer: withCellRenderState(RatingCellRenderer),
											stickyGroupCellRenderer: withCellRenderState(StickyGroupCellRenderer),
											percentageCellRenderer: withCellRenderState(PercentageCellRenderer),

										}}
										disableClickSelectionRenderers={['actionCellRenderer']}
										onRowClicked={onRowClicked}
										// rowHeight={_.isEqual(width, 'xs') ? 60 : 50}
										// noDataTxt={!_.isEqual(currentUser, 'All') ? `No ${currentUser} users found` : 'No users found'}
									/> */}
									{/* {!loading && (
										<TablePagination
											component='div'
											count={totalCount}
											page={param.offset / param.limit}
											onPageChange={onChangePage}
											rowsPerPage={param.limit}
											onRowsPerPageChange={changeRowPerPage}
										/>
									)} */}
								</div>
							)}

							{!isFetching && (
								<ActivityDrawer
									isSocketConnected={isSocketConnected}
									socketRef={socketIO}
									open={activityDrawer}
									handleClose={handleCloseActivity}
									glassCode={glassCode}
								/>
							)}
						</div>
						<StickySlider
							match={match}
							onClose={handleCloseSlider}
							glassCode={glassCode}
							sessionId={sessionId}
							glassLabels={glassLabels}
							glassMembers={glassMembers}
							isOwner={isOwner}
							isMember={isMember}
							activityTypes={activityTypes}
							socketRef={socketIO}
							setSocketState={setSocketState}
							isSocketConnected={isSocketConnected}
							authToken={authToken}
							history={history}
							isGuest={isGuest}
							lanesData={lanesData}
							location={location}
							glassStickyGroups={glassStickyGroups}
						/>
						<Grow in={!filterMenuProps.isOpen && !settings} unmountOnExit>
							<Fab
								className={classes.fab}
								color='primary'
								size='small'
								onClick={() => {
									setMenuProps((prev) => ({
										...prev,
										isOpen: true,
									}))
								}}
							>
								{isFilterActive ? (
									<Badge color='error' badgeContent={''} variant='dot'>
										<Icon>filter_list</Icon>
									</Badge>
								) : (
									<Icon>filter_list</Icon>
								)}
							</Fab>
						</Grow>
						{!isFetching && filterMenuProps.isOpen && !settings && (
							<div className={classnames(classes.menuContainer, { [classes.openMenu]: filterMenuProps.isOpen })}>
								<FilterMenuList token={authToken} activeFilters={filter?.filterData} setMenuProps={setMenuProps} updateFunc={updateFilter} />
							</div>
						)}
					</Grid>
				)}
			</SocketContext.Provider>
			{/* <GlassXFilter open={openFilter} onClose={handleCloseFilter} /> */}
		</div>
	)
}

export default GlassX
